import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, IconButton, Typography } from '@mui/material'
import { DeleteIcon, PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { useActions } from '../../hooks/useActions'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { editUserSchema } from '../../infrasturcture/yupSchemes/createUserSchema'
import { Section } from '../../types/sectionsTypes'
import { Warehouse } from '../../types/warehousesTypes'
import { UserForm } from './ui/UserForm'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import styles from './Users.module.css'
import { formatPhone } from '../../infrasturcture/utils/formatPhone'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

export const EditUser: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    getUserThunkCreator,
    setUser,
    updateUserThunkCreator,
    getSectionsThunkCreator,
    getWarehousesThunkCreator,
    deleteUserThunkCreator
  } = useActions()
  const { user, users, isLoaded } = useTypeSelector((state) => state.users)
  const { sections } = useTypeSelector((state) => state.sections)
  const { warehouses } = useTypeSelector((state) => state.warehouses)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isWarning, setIsWarning] = useState<boolean>(false)

  const fullName = [user?.firstname, user?.lastname].join(' ').trim()

  const methods = useForm({ resolver: yupResolver(editUserSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    return updateUserThunkCreator(
      id!,
      {
        ...data,
        phone: formatPhone(data.phone, true),
        sections: data?.sections?.map((s: Section) => s.id),
        warehouses: data?.warehouses?.flatMap((w: Warehouse) =>
          w.booking_warehouse_id ? [w.id, w.booking_warehouse_id] : [w.id]
        ),
        mainWarehouses: data?.warehouses?.map((w: Warehouse) => w.id)
      },
      () => setIsEdit(false),
      navigate
    )
  }

  const deleteUser = () => {
    deleteUserThunkCreator(id!, () => {
      setIsWarning(false)
      navigate('/users')
    })
  }

  const handleEdit = () => setIsEdit(true)
  const handleWarning = () => setIsWarning(true)

  const handleFetch = async (id: string) => {
    !sections.length && (await getSectionsThunkCreator())
    await getWarehousesThunkCreator({ isBooking: false })
    const findUser = users.find((u) => u.id === id)
    findUser ? setUser(findUser) : getUserThunkCreator(id, navigate)
  }

  useEffect(() => {
    handleFetch(id!)
  }, [])

  useEffect(() => {
    if (user) {
      methods.reset({
        ...user,
        phone: user.phone ?? '',
        company: user.company ?? '',
        lastname: user.lastname ?? '',
        sections: sections.filter((item) => user.sections.includes(item.id)) as any,
        warehouses: warehouses.filter((item) => user.warehouses.includes(item.id)) as any
      })
    }
  }, [user])

  return (
    <div className={styles.container}>
      {!isLoaded && user && (
        <>
          {isMobile && (
            <MobileHeader>
              {isEdit ? (
                <>
                  <SaveIcon onClick={methods.handleSubmit(onSubmit)} />
                  <DeleteIcon onClick={handleWarning} />
                </>
              ) : (
                <PenIcon onClick={handleEdit} />
              )}
            </MobileHeader>
          )}
          <Breadcrumbs customText={fullName} />
          <StyledHeader>
            <Typography variant="h1">{fullName}</Typography>
            {!isMobile && (
              <>
                {isEdit ? (
                  <>
                    <Button
                      onClick={methods.handleSubmit(onSubmit)}
                      disabled={methods.formState.isSubmitting}
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      Сохранить
                    </Button>
                    <Button onClick={handleWarning} color="error" startIcon={<DeleteIcon />}>
                      Удалить
                    </Button>
                  </>
                ) : (
                  <IconButton color="inherit" onClick={handleEdit}>
                    <PenIcon />
                  </IconButton>
                )}
              </>
            )}
          </StyledHeader>
          <FormProvider {...methods}>
            <UserForm type="edit" disabled={!isEdit} onSubmit={onSubmit} />
          </FormProvider>
        </>
      )}
      <SnacbarModal
        onAccept={deleteUser}
        onCancel={() => setIsWarning(false)}
        open={isWarning}
        message={`Вы действительно хотите удалить пользователя '${[user?.lastname, user?.firstname].join(' ').trim()}'`}
      />
    </div>
  )
}
