import { Box } from '@mui/material'
import React from 'react'
import { OperationTitle } from '../../../components/Operations/OperationTitle'
import { VgTable } from '../../../components/VgTable/VgTable'
import { getBookingTableConfig } from '../../bookings/helpers/getBookingTableConfig'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

interface OrderProductsProps {
  type: 'create' | 'edit'
  disabled?: boolean
}
export const OrderProducts: React.FC<OrderProductsProps> = ({ type, disabled = false }) => {
  const { isMobile } = useMediaQueryContext()
  const { productsInOrder } = useTypeSelector((state) => state.orders)
  const { orderProductsColumns } = getBookingTableConfig({ type, operationType: 'order', disabled })

  return (
    <Box>
      {!!productsInOrder.length && (
        <Box sx={{ mt: '40px' }}>
          <OperationTitle variant="large">Товары в заявке</OperationTitle>
          <VgTable
            data={productsInOrder}
            columns={orderProductsColumns}
            strokeRow={(item) => item.count_left === 0}
            mobileMode={isMobile}
          />
        </Box>
      )}
    </Box>
  )
}
