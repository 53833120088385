import React from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import { SearchInput } from '../../../components/SearchInput/SearchInput'
import { Input } from '../../../components/Input/Input'
import { Textarea } from '../../../components/Input/Textarea'
import { OperationSearch } from '../../../components/Operations/OperationSearch'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { BookingProduct } from '../../../types/bookingsTypes'
import formStyles from '../../../styles/Form.module.css'
import { getStatusOptions } from '../../../infrasturcture/utils/getStatusOptions'
import { Select } from '../../../components/Select/Select'
import { DateInput } from '../../../components/DateInput/DateInput'

interface OrderFormProps {
  onSubmit: SubmitHandler<any>
  type: 'create' | 'edit'
  disabled?: boolean
}

export const OrderForm: React.FC<OrderFormProps> = ({ onSubmit, disabled, type }) => {
  const { user } = useTypeSelector((state) => state.user)
  const { order, productsInOrder, originalProducts: originalOrderProducts } = useTypeSelector((state) => state.orders)
  const { products } = useTypeSelector((state) => state.operations)
  const { filterWarehouses } = useTypeSelector((state) => state.warehouses)
  const { getProductsInWarehouseThunkCreator, addOrderProduct, setOrderProducts, setOrdersErrors } = useActions()

  const isDisabled = type === 'edit' && disabled

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext()

  const statusOptions = getStatusOptions(user?.role, order?.status)

  const warehouseId = watch('warehouse_id')

  const handleWarehouseChange = (warehouseId: string | null) => {
    setValue('warehouse_id', warehouseId, { shouldValidate: true })
    setOrderProducts([])
    getProductsInWarehouseThunkCreator(null)
  }

  const handleAddProduct = (product: BookingProduct) => {
    const isExistProduct = productsInOrder.find((item) => item.id === product.id)
    if (!isExistProduct) {
      const prevOrderProduct = originalOrderProducts.find((p) => p.id === product.id)
      addOrderProduct(prevOrderProduct ?? { ...product, count: 1, count_left: 1, write_off_left: 1, checkbox: false })
    }
    setOrdersErrors({})
  }

  const handleFocus = () => {
    if (!warehouseId) {
      setError('warehouse_id', { message: 'Укажите склад' })
    }
  }

  return (
    <div className={formStyles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={formStyles.formItem}>
          <Typography variant="h2" color="text.primary">
            Общая информация
          </Typography>
          <Grid className={formStyles.fields} container spacing={3}>
            <Grid container item xs={12} md={6} spacing={3}>
              <Grid item xs={12}>
                <SearchInput
                  onChange={handleWarehouseChange}
                  options={filterWarehouses.map((w) => ({ label: w.name, value: w.id }))}
                  value={warehouseId}
                  label={errors?.warehouse_id?.message ?? 'Склад'}
                  placeholder="Склад"
                  error={!!errors?.warehouse_id?.message}
                  disabled={type === 'edit'}
                />
              </Grid>
              {type === 'edit' && (
                <>
                  <Grid item xs={12}>
                    <Select control={control} options={statusOptions} label="Статус" name="status" disabled={isDisabled} />
                  </Grid>
                  <Grid item xs={12}>
                    <Input control={control} label="Менеджер" placeholder="Менеджер" name="full_name" disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <Input control={control} label="Дата создания" placeholder="Дата создания" name="created_at" disabled />
                  </Grid>
                </>
              )}
              {type === 'create' && (
                <Grid item xs={12}>
                  <Input control={control} label="Id B24" placeholder="Id B24" name="bitrix_id" disabled={isDisabled} />
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} md={6} spacing={3}>
              {type === 'edit' && (
                <>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      label="Id B24"
                      placeholder="Id B24"
                      name="bitrix_id"
                      disabled={type === 'edit' || isDisabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DateInput
                      control={control}
                      name="ready_at"
                      label="Планируемая дата готовности"
                      error={!!errors?.ready_at?.message}
                      minDate={new Date()}
                      disabled={isDisabled}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Textarea
                  control={control}
                  label={errors?.comment?.message ?? 'Комментарий'}
                  placeholder="Комментарий"
                  name="comment"
                  rows={4.4}
                  error={!!errors?.comment?.message}
                  disabled={type === 'edit' || isDisabled}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: '-8px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: 12, lineHeight: '16px' }} component="span" color="secondary.main">
                * Оформить заявку можно только для склада с указанным регионом.
              </Typography>
            </Grid>
            {!isDisabled && (
              <Grid item xs={12} md={6} sx={{ mt: { xs: '8px', md: '16px' } }}>
                <OperationSearch
                  onSelect={handleAddProduct}
                  onFocus={handleFocus}
                  options={products.map((p) => ({ label: p.name, value: p }))}
                  renderOption={(product) => <ProductInfo product={product} isOption isShowTooltip={false} />}
                  disabled={!!errors?.warehouse_id?.message || !!errors?.warehouse_id?.message}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </form>
    </div>
  )
}
