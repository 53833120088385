import { Box, SxProps, Typography } from '@mui/material'
import React, { PropsWithChildren, useState } from 'react'
import { InfoIcon } from '../../infrasturcture/icons'
import { MuiTooltip } from '../MuiComponents/CustomTooltip/MuiTooltip'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'

const defaultText = `Количество товаров, указанное в товарах в операции, указано с учетом товаров под заявки/бронирования 
для данной операции.`

interface OperationTitleProps extends PropsWithChildren<unknown> {
  isShowTooltip?: boolean
  variant?: 'small' | 'large'
  tooltipText?: string
}

export const OperationTitle: React.FC<OperationTitleProps> = ({
  children,
  isShowTooltip = false,
  variant = 'small',
  tooltipText = defaultText
}) => {
  const { isMobile } = useMediaQueryContext()
  const [open, setOpen] = useState<boolean>(false)

  const sx: SxProps = {
    fontSize: variant === 'small' ? 15 : 18,
    fontWeight: variant === 'small' ? 700 : 500,
    lineHeight: variant === 'small' ? '21px' : '24px'
  }

  const handleOpen = () => {
    if (!isMobile) return setOpen(true)

    if (!open) {
      setOpen(true)
      setTimeout(() => setOpen(false), 3000)
    }
  }

  const handleClose = () => setOpen(false)

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', mb: { xs: '12px', md: '20px' } }}>
      <Typography variant="h3" color="text.primary" sx={sx}>
        {children}
      </Typography>
      {isShowTooltip && (
        <MuiTooltip
          sx={{ maxWidth: { xs: '324px' } }}
          title={tooltipText}
          leaveDelay={500}
          placement={isMobile ? 'bottom-start' : 'right-start'}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: isMobile ? [110, -5] : [14, -15]
                }
              }
            ]
          }}
          onOpen={handleOpen}
          onClose={handleClose}
          open={open}
          disableFocusListener={isMobile}
          disableHoverListener={isMobile}
          disableTouchListener={isMobile}
        >
          <InfoIcon
            style={{
              position: 'absolute',
              right: -18,
              top: variant === 'small' ? -10 : -8,
              width: 16,
              height: 16,
              fill: '#0C4F3F'
            }}
            onClick={isMobile ? handleOpen : undefined}
          />
        </MuiTooltip>
      )}
    </Box>
  )
}
