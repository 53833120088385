import React, { useEffect, useState } from 'react'
import { ProductForm } from './ui/ProductForm'
import { useActions } from '../../hooks/useActions'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { createProductSchema as editProductSchema } from '../../infrasturcture/yupSchemes/createProductSchema'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFileReader } from '../../hooks/useFileReader'
import { roles } from '../../types/userTypes'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import styles from './CreateProduct.module.css'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, IconButton, Typography } from '@mui/material'
import { DeleteIcon, PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'

export const EditProduct: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    getSectionsThunkCreator,
    getProductThunkCreator,
    getManufacturersThunkCreator,
    editProductThunkCreator,
    deleteProductThunkCreator,
    setProduct,
    setProductsErrors
  } = useActions()
  const { user } = useTypeSelector((state) => state.user)
  const { products, product, isLoaded, errors } = useTypeSelector((state) => state.products)
  const { sections } = useTypeSelector((state) => state.sections)
  const { manufacturers } = useTypeSelector((state) => state.manufacturers)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isWarning, setIsWarning] = useState<boolean>(false)

  const fileReader = useFileReader()

  const methods = useForm({ resolver: yupResolver(editProductSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    return editProductThunkCreator(
      {
        ...data,
        section_name: sections.find((s) => s.id === data.section_id)?.name,
        manufacturer_name: manufacturers.find((m) => m.id === data.manufacturer_id)?.name || null
      },
      () => {
        setIsEdit(false)
      },
      navigate
    )
  }

  const handleEdit = () => setIsEdit(true)
  const handleWarning = () => setIsWarning(true)

  const fetchData = async (id: string) => {
    !sections.length && (await getSectionsThunkCreator())
    if (!manufacturers.length && [roles.ADMINISTRATOR, roles.SUPER_MANAGER].includes(user?.role)) {
      await getManufacturersThunkCreator()
    }
    const findProduct = products.find((p) => p.id === id)
    findProduct ? setProduct(findProduct) : getProductThunkCreator(id, navigate)
  }

  const handleDelete = () => {
    if (product) {
      deleteProductThunkCreator(product.id, () => navigate('/products'), navigate)
      setIsWarning(false)
    }
  }

  useEffect(() => {
    fetchData(id!)
  }, [])

  useEffect(() => {
    if (product) {
      methods.reset(
        Object.entries(product).reduce((obj, [key, value]) => {
          return { ...obj, [key]: value === null ? '' : value }
        }, {})
      )
      product?.photo && fileReader.setImage(`${process.env.REACT_APP_API_URL}/static/images/${product?.photo}`)
    }
  }, [product])

  return (
    <div className={styles.container}>
      {!isLoaded && product && (
        <>
          {isMobile && (
            <MobileHeader>
              {isEdit ? (
                <>
                  <SaveIcon onClick={methods.handleSubmit(onSubmit)} />
                  <DeleteIcon onClick={handleWarning} />
                </>
              ) : (
                user?.role !== roles.DEALER && <PenIcon onClick={handleEdit} />
              )}
            </MobileHeader>
          )}
          <Breadcrumbs customText={product.name} />
          <StyledHeader>
            <Typography variant="h1">{product.name}</Typography>
            {!isMobile && (
              <>
                {isEdit ? (
                  <>
                    <Button
                      onClick={methods.handleSubmit(onSubmit)}
                      disabled={methods.formState.isSubmitting}
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      Сохранить
                    </Button>
                    <Button onClick={handleWarning} color="error" startIcon={<DeleteIcon />}>
                      Удалить
                    </Button>
                  </>
                ) : (
                  user?.role !== roles.DEALER && (
                    <IconButton color="inherit" onClick={handleEdit}>
                      <PenIcon />
                    </IconButton>
                  )
                )}
              </>
            )}
          </StyledHeader>
          <FormProvider {...methods}>
            <ProductForm type="edit" onSubmit={onSubmit} fileReader={fileReader} disabled={!isEdit} />
          </FormProvider>
        </>
      )}
      <SnacbarModal
        onAccept={handleDelete}
        onCancel={() => setIsWarning(false)}
        open={isWarning}
        message={`Вы действительно хотите удалить товар '${product?.name}'`}
      />
      <SnacbarModal isError isActions={false} onCancel={() => setProductsErrors({})} open={!!errors?.id} message={errors?.id} />
    </div>
  )
}
