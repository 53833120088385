import { ProductsState, ProductsAction, ProductsActionTypes } from '../../types/productsTypes'

const initialState: ProductsState = {
  products: [],
  product: null,
  count: 0,
  limit: 30,
  searchProducts: [],
  isLoaded: false,
  errors: {},
  filters: {
    section: null,
    subsections: [],
    custom: false
  }
}

export const productsReducer = (state = initialState, action: ProductsAction): ProductsState => {
  switch (action.type) {
    case ProductsActionTypes.GET_PRODUCTS:
      return {
        ...state,
        isLoaded: false,
        products: [...state.products, ...action.payload.rows],
        count: action.payload.count,
        product: null
      }
    case ProductsActionTypes.GET_PRODUCT:
      return {
        ...state,
        isLoaded: false,
        product: action.payload
      }
    case ProductsActionTypes.UPDATE_PRODUCT:
      return {
        ...state,
        errors: {},
        product: action.payload
      }
    case ProductsActionTypes.SET_PRODUCT:
      return {
        ...state,
        product: action.payload
      }
    case ProductsActionTypes.SEARCH_PRODUCTS:
      return {
        ...state,
        searchProducts: action.payload
      }
    case ProductsActionTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case ProductsActionTypes.SET_PRODUCT_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      }
    case ProductsActionTypes.SET_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }
    case ProductsActionTypes.DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter((item) => item.id !== action.payload)
      }
    case ProductsActionTypes.SET_PRODUCTS_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}
