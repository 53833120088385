import React, { useEffect, useState } from 'react'
import styles from '../../styles/Operations.module.css'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, IconButton, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { OperationProducts } from './ui/OperationProducts'
import { OperationForm } from './ui/OperationForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { yupResolver } from '@hookform/resolvers/yup'
import { createOperactionSchema } from '../../infrasturcture/yupSchemes/createOperactionSchema'
import { PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { operationTypesRus } from '../../types/operationsTypes'
import { format } from 'date-fns'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

export const EditOperation: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const { operation, isLoaded } = useTypeSelector((state) => state.operations)
  const { warehouses } = useTypeSelector((state) => state.warehouses)
  const { getOperationThunkCreator, getWarehousesThunkCreator, editOperationThunkCreator } = useActions()

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const methods = useForm({ resolver: yupResolver(createOperactionSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    return editOperationThunkCreator(data, () => setIsEdit(false), navigate)
  }

  const handleEdit = () => setIsEdit(true)

  useEffect(() => {
    getOperationThunkCreator(id!, navigate)
    !warehouses.length && getWarehousesThunkCreator({ isBooking: operation?.type === 'POSTING' ? false : null })
  }, [])

  useEffect(() => {
    if (operation) {
      methods.reset({
        ...operation,
        full_name: `${operation.user_lastname || ''} ${operation.user_firstname}`.trim(),
        created_at: format(new Date(operation.created_at), 'dd.MM.yyyy  HH:mm'),
        purpose: operation.purpose || ''
      })
    }
  }, [operation])

  return (
    <div className={styles.container}>
      {!isLoaded && operation && (
        <>
          {isMobile && (
            <MobileHeader>
              {isEdit ? <SaveIcon onClick={methods.handleSubmit(onSubmit)} /> : <PenIcon onClick={handleEdit} />}
            </MobileHeader>
          )}
          <Breadcrumbs customText={`${operationTypesRus[operation.type]} №${operation.sku}`} />
          <StyledHeader>
            <Typography variant="h1">
              {operationTypesRus[operation.type]} №{operation.sku}
            </Typography>
            {!isMobile && (
              <>
                {isEdit ? (
                  <Button
                    onClick={methods.handleSubmit(onSubmit)}
                    disabled={methods.formState.isSubmitting}
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    Сохранить
                  </Button>
                ) : (
                  <IconButton color="inherit" onClick={handleEdit}>
                    <PenIcon />
                  </IconButton>
                )}
              </>
            )}
          </StyledHeader>
          <FormProvider {...methods}>
            <OperationForm type="edit" disabled={!isEdit} operationType={operation.type} onSubmit={onSubmit} />
          </FormProvider>
          <OperationProducts type="edit" />
        </>
      )}
    </div>
  )
}
