import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Sidebar.module.css'
import { Route } from '../../infrasturcture/routes/routes'
import { ArrowRightSmallIcon } from '../../infrasturcture/icons'
import clsx from 'clsx'
import { StyledTooltip } from '../../infrasturcture/theme/styled'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'

interface SidebarLinkProps {
  isOpen: boolean
  onClick: (e: React.MouseEvent, route: Route) => void
  route: Route
}

export const SidebarLink: React.FC<SidebarLinkProps> = ({ isOpen, route, onClick }) => {
  const { isMobile } = useMediaQueryContext()

  return (
    <StyledTooltip
      title={!isOpen ? route.label : ''}
      placement="right"
      arrow
      disableHoverListener={isMobile}
      disableTouchListener={isMobile}
      disableFocusListener={isMobile}
    >
      <li className={styles.item}>
        <NavLink
          className={({ isActive }) => clsx(styles.link, { [styles.active]: isActive })}
          to={route.path}
          onClick={(e) => onClick(e, route)}
        >
          {route.icon && <route.icon className={styles.linkIcon} />}
          <span className={styles.linkText}>{route.label}</span>
          {route?.childrens && <ArrowRightSmallIcon className={styles.nestedIcon} />}
        </NavLink>
      </li>
    </StyledTooltip>
  )
}
