import React from 'react'
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

export const MuiTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: 50,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#D3DFDC',
    color: theme.palette.text.primary,
    maxWidth: 348,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    padding: 20,
    borderRadius: 8,
    boxShadow: '-2px 0px 4px 0px #00000014, 0px 2px 4px 0px #00000014',
    '@media screen and (max-width: 500px)': {
      maxWidth: '100%'
    }
  }
}))
