import * as UserActions from './userActions'
import * as UsersActions from './usersActions'
import * as ProdustsActions from './productsActions'
import * as WarehousesActions from './warehousesActions'
import * as OperationsActions from './operationsActions'
import * as BalancesActions from './balancesActions'
import * as OrdersActions from './ordersActions'
import * as SectionsActions from './sectionsActions'
import * as BookingsActions from './bookingsActions'
import * as ManufacturersActions from './manufacturersActions'
import * as LayoutActions from './layoutActions'

export default {
  ...UserActions,
  ...UsersActions,
  ...ProdustsActions,
  ...WarehousesActions,
  ...OperationsActions,
  ...BalancesActions,
  ...OrdersActions,
  ...SectionsActions,
  ...BookingsActions,
  ...ManufacturersActions,
  ...LayoutActions
}
