import React from 'react'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { OperationTitle } from '../../../components/Operations/OperationTitle'
import { VgTable } from '../../../components/VgTable/VgTable'
import { StyledLinkRouter } from '../../../infrasturcture/theme/styled'
import styles from '../../../styles/OperationRow.module.css'
import { getBookingTableConfig } from '../helpers/getBookingTableConfig'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

const orderTooltipText = `
Автоматически созданная заявка для данного 
бронирования. Статус заявки совпадает со 
статусом бронирования
`

export const BookingRow: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { booking } = useTypeSelector((state) => state.bookings)
  const { bookingProductsColumns, orderProductsColumns } = getBookingTableConfig({
    type: 'edit',
    operationType: 'booking',
    disabled: true
  })

  return (
    <>
      {booking && (
        <tr className={styles.row}>
          <td className={styles.cell} colSpan={!isMobile ? 8 : undefined}>
            {!!booking?.productsInBooking?.length && (
              <div className={styles.wrapper}>
                <OperationTitle>Товары в бронировании</OperationTitle>
                <VgTable
                  classNameRow={styles.tableRow}
                  data={booking.productsInBooking}
                  columns={bookingProductsColumns}
                  strokeRow={(item) => item.write_off_left === 0}
                  mobileMode={isMobile}
                />
              </div>
            )}
            {!!booking?.productsInOrder?.length && (
              <div className={styles.wrapper}>
                <OperationTitle tooltipText={orderTooltipText} isShowTooltip>
                  Товары в заявке&nbsp;
                  <StyledLinkRouter target="_blank" to={`/orders/${booking?.sku}`}>
                    №{booking?.sku}
                  </StyledLinkRouter>
                </OperationTitle>
                <VgTable
                  classNameRow={styles.tableRow}
                  data={booking.productsInOrder}
                  columns={orderProductsColumns}
                  strokeRow={(item) => item.write_off_left === 0}
                  mobileMode={isMobile}
                />
              </div>
            )}
          </td>
        </tr>
      )}
    </>
  )
}
