import React, { useRef } from 'react'
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useActions } from '../../../hooks/useActions'
import { Box, styled, Typography } from '@mui/material'
import { FileReaderType } from '../../../hooks/useFileReader'
import { money } from '../../../types/productsTypes'
import { Input } from '../../../components/Input/Input'
import { Select } from '../../../components/Select/Select'
import { Textarea } from '../../../components/Input/Textarea'
import { Checkbox } from '../../../components/Checkbox/Checkbox'
import clsx from 'clsx'
import styles from './ProductForm.module.css'
import { roles } from '../../../types/userTypes'
import { CloseMediumIcon, SelectPhoto } from '../../../infrasturcture/icons'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  color: theme.palette.primary.main
}))

const StyledBoxButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 10,
  right: 10,
  width: 30,
  height: 30,
  borderRadius: '50%',
  backgroundColor: '#ffffff',
  boxShadow: '1px 2px 4px 0px #00000014',
  color: theme.palette.primary.main,
  fill: 'currentcolor',
  cursor: 'pointer'
}))

interface ProductFormProps {
  onSubmit: SubmitHandler<any>
  fileReader: FileReaderType
  type: 'create' | 'edit'
  disabled?: boolean
}

const accessRoles: string[] = [roles.SUPER_MANAGER, roles.ADMINISTRATOR]

export const ProductForm: React.FC<ProductFormProps> = ({ onSubmit, fileReader, disabled = false, type }) => {
  const { user } = useTypeSelector((state) => state.user)
  const { errors: stateErrors } = useTypeSelector((state) => state.products)
  const { sections } = useTypeSelector((state) => state.sections)
  const { manufacturers } = useTypeSelector((state) => state.manufacturers)
  const { setProductsErrors } = useActions()

  const fileInput = useRef<HTMLInputElement>(null)

  const isDisabled = type === 'edit' && disabled
  const isAccess = accessRoles.includes(user?.role)

  const {
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useFormContext()

  const sectionId: string = watch('section_id')
  const subsections = sections?.find((s) => s.id === sectionId)?.subsections || []

  const handleImageClick = () => fileInput.current?.click()

  const handleImageChange = (e: any) => {
    setValue('photo', e.target.files[0], { shouldDirty: true })
    fileReader.setFile(e.target.files[0])
    clearErrors('photo')
  }

  const handleDeletePhoto = () => {
    fileReader.setImage(null)
    setValue('photo', null, { shouldDirty: true })
  }

  const cleanError = (name: 'name' | 'sku') => {
    const { [name]: _, ...rest } = stateErrors
    setProductsErrors(rest)
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.container}>
        <div className={styles.containerItem}>
          <Typography variant="h2" color="text.primary">
            Общая информация
          </Typography>
          <div className={styles.grid}>
            <div className={clsx(styles.fileUpload, { [styles.disabled]: isDisabled })}>
              <span className={styles.fieldLabel}>Фото</span>
              <input ref={fileInput} onChange={handleImageChange} type="file" accept="image/*" hidden />
              {!isDisabled && fileReader.image && (
                <StyledBoxButton onClick={handleDeletePhoto}>
                  <CloseMediumIcon />
                </StyledBoxButton>
              )}
              <div className={styles.fileUpload_imageWrapper} onClick={handleImageClick}>
                {fileReader.image ? (
                  <img className={styles.fileUpload_image} src={fileReader.image} alt="image" />
                ) : (
                  <StyledBox>
                    <SelectPhoto width={30} height={30} />
                  </StyledBox>
                )}
              </div>
            </div>
            <div className={styles.fields}>
              <Input
                onChange={() => cleanError('name')}
                control={control}
                label={(stateErrors?.name || errors?.name?.message) ?? 'Название товара'}
                placeholder="Название товара"
                name="name"
                error={!!stateErrors?.name || !!errors?.name?.message}
                disabled={isDisabled}
                autoComplete="off"
              />
              <Input
                onChange={() => cleanError('sku')}
                control={control}
                label={(stateErrors?.sku || errors?.sku?.message) ?? 'Код товара'}
                placeholder="Код товара"
                name="sku"
                error={!!stateErrors?.sku || !!errors?.sku?.message}
                disabled={isDisabled}
                autoComplete="off"
              />
              <Select
                control={control}
                options={sections.map((item) => ({ value: item.id, label: item.name }))}
                label={errors?.section_id?.message ?? 'Раздел'}
                name="section_id"
                error={!!errors?.section_id?.message}
                disabled={isDisabled}
              />
              <Select
                control={control}
                options={[{ value: '', label: '<Не выбрано>' }, ...subsections.map((name) => ({ value: name, label: name }))]}
                label={errors?.subsection?.message ?? 'Подраздел'}
                name="subsection"
                error={!!errors?.subsection?.message}
                disabled={isDisabled}
              />
              <Input
                control={control}
                label={errors?.color?.message ?? 'Цвет'}
                placeholder="Цвет"
                name="color"
                error={!!errors?.color?.message}
                disabled={isDisabled}
                autoComplete="off"
              />
              {isAccess && (
                <Input
                  control={control}
                  label={errors?.retail_price?.message ?? 'Закупочная цена'}
                  placeholder="Закупочная цена"
                  name="retail_price"
                  symbol={money.RUB}
                  error={!!errors?.retail_price?.message}
                  disabled={isDisabled}
                  autoComplete="off"
                />
              )}
              <Select
                control={control}
                options={[
                  { value: '', label: '<Не выбрано>' },
                  ...manufacturers.map((item) => ({ value: item.id, label: item.name }))
                ]}
                label={errors?.manufacturer_id?.message ?? 'Производитель'}
                name="manufacturer_id"
                error={!!errors?.manufacturer_id?.message}
                disabled={isDisabled}
              />
              <Input
                control={control}
                label={errors?.purchase_price?.message ?? 'Розничная цена'}
                placeholder="Розничная цена"
                name="purchase_price"
                symbol={money.RUB}
                error={!!errors?.purchase_price?.message}
                disabled={isDisabled}
                autoComplete="off"
              />
            </div>
          </div>
          <div className={styles.containerItem}>
            <Typography variant="h2" color="text.primary">
              Дополнительно
            </Typography>
            <div className={styles.grid}>
              <div className={styles.fieldsVertical}>
                <Input
                  control={control}
                  label={errors?.delivery_time?.message ?? 'Время доставки'}
                  placeholder="Время доставки"
                  name="delivery_time"
                  symbol="дн"
                  error={!!errors?.delivery_time?.message}
                  disabled={isDisabled}
                  autoComplete="off"
                />
                {isAccess && (
                  <Input
                    control={control}
                    label={errors?.name_1c?.message ?? 'Название в 1С'}
                    placeholder="Название в 1С"
                    name="name_1c"
                    error={!!errors?.name_1c?.message}
                    disabled={isDisabled}
                    autoComplete="off"
                  />
                )}
              </div>
              <div className={styles.fields}>
                <Textarea
                  control={control}
                  label="Описание"
                  placeholder="Описание"
                  name="description"
                  rows={4.4}
                  disabled={isDisabled}
                />
                <Textarea
                  control={control}
                  label="Описание (Заказной)"
                  placeholder="Описание (Заказной)"
                  name="description_custom"
                  rows={4.4}
                  disabled={isDisabled || !watch('custom')}
                />
              </div>
            </div>
            <div className={styles.checkbox}>
              <Controller
                control={control}
                defaultValue={false}
                name="custom"
                render={({ field }) => (
                  <Checkbox
                    onChange={field.onChange}
                    label="Заказной"
                    labelPosition="before"
                    value={field.value}
                    checked={field.value}
                    disabled={isDisabled}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
