import React, { useEffect, useState } from 'react'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { Route, routes } from '../../infrasturcture/routes/routes'
import { ReactComponent as BasaltText } from '../../assets/images/basalt-text.svg'
import { SidebarLink } from './SidebarLink'
import { CollapseButton } from './CollapseButton'
import { LogoutButton } from './LogoutButton'
import clsx from 'clsx'
import { useLocation, NavLink } from 'react-router-dom'
import { NestedLink } from './NestedLink'
import { ArrowRightIcon } from '../../infrasturcture/icons'
import { useActions } from '../../hooks/useActions'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import styles from './Sidebar.module.css'

export const Sidebar = React.memo(() => {
  const { isMobile } = useMediaQueryContext()
  const { setIsMenuOpen } = useActions()
  const { pathname } = useLocation()
  const { isMenuOpen } = useTypeSelector((state) => state.layout)
  const { user } = useTypeSelector((state) => state.user)
  const [nested, setNested] = useState<Route | null>(null)

  const toggleIsOpen = () => setIsMenuOpen(!isMenuOpen)

  const handleClick = (e: React.MouseEvent, route: Route) => {
    if (Array.isArray(route.childrens) && (pathname === route.path || pathname.startsWith(route.path))) {
      e.preventDefault()
      return setNested(route.path !== nested?.path ? route : null)
    }
    setNested(null)
    isMobile && toggleIsOpen()
  }

  const handleClickNested = () => {
    setNested(null)
    isMobile && toggleIsOpen()
  }

  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = isMenuOpen ? 'hidden' : ''

      return () => {
        document.body.style.overflow = ''
      }
    }
  }, [isMenuOpen])

  return (
    <div className={clsx(styles.container, { [styles.collapse]: !isMenuOpen, [styles.nested]: !!nested })}>
      <div className={styles.header}>
        {isMobile && <ArrowRightIcon onClick={toggleIsOpen} className={styles.collapseIcon_mobile} />}
        <BasaltText />
      </div>
      <nav className={styles.nav}>
        <ul className={styles.links}>
          {routes.map(
            (item, i) =>
              item.isDisplay &&
              item.roles.includes(user?.role) && <SidebarLink key={i} route={item} isOpen={isMenuOpen} onClick={handleClick} />
          )}
          <LogoutButton isOpen={isMenuOpen} />
          {!isMobile && <CollapseButton isOpen={isMenuOpen} onClick={toggleIsOpen} />}
        </ul>
      </nav>
      <div className={clsx(styles.nestedMenu, { [styles.open]: !!nested })}>
        <div className={styles.nestedMenuWrapper}>
          {!!nested && (
            <>
              <div className={styles.nestedMenuHeader}>
                {isMobile && <ArrowRightIcon onClick={() => setNested(null)} className={styles.collapseIconNestedMenu_mobile} />}
                {nested?.icon && <nested.icon className={styles.linkIcon} />}
                <span className={styles.nestedLinkText}>{nested?.label}</span>
              </div>
              <ul className={styles.links}>
                <li className={styles.item}>
                  <NavLink
                    onClick={handleClickNested}
                    className={clsx(styles.nestedLink, { [styles.active]: nested.path === pathname })}
                    to={nested.path}
                  >
                    <span className={styles.nestedLinkText}>{nested.nestedLabel}</span>
                  </NavLink>
                </li>
                {nested.childrens?.map(
                  (item, i) => item.roles.includes(user?.role) && <NestedLink key={i} onClick={handleClickNested} route={item} />
                )}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  )
})
