import React, { useEffect } from 'react'
import { rolesRus } from '../../types/userTypes'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { Users as UserType } from '../../types/usersTypes'
import { Button, Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledCell, StyledHeader } from '../../infrasturcture/theme/styled'
import { PlusIcon } from '../../infrasturcture/icons'
import { VgTable, VgTableColumnType } from '../../components/VgTable/VgTable'
import { formatPhone } from '../../infrasturcture/utils/formatPhone'
import { useNavigate } from 'react-router-dom'
import { Text } from '../../components/Text/Text'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'
import styles from './Users.module.css'

const tableConfig: VgTableColumnType<UserType>[] = [
  {
    key: 'firstname',
    title: 'Пользователь',
    width: '23%',
    render: (_, { firstname, lastname, role }) => (
      <Text title={`${lastname || ''} ${firstname}`.trim()} subtitle={rolesRus[role]} />
    )
  },
  {
    key: 'company',
    title: 'Компания',
    width: '23%',
    render: (_, { company }) => <StyledCell>{company || ''}</StyledCell>
  },
  {
    key: 'email',
    title: 'Почта',
    width: '24%'
  },
  {
    key: 'phone',
    title: 'Телефон',
    width: '30%',
    render: (_, { phone }) => (phone ? formatPhone(phone) : '')
  }
]

export const Users: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { users } = useTypeSelector((state) => state.users)
  const { getUsersThunkCreator } = useActions()

  useEffect(() => {
    getUsersThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileHeader>
          <PlusIcon onClick={() => navigate('/users/create')} />
        </MobileHeader>
      )}
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Пользователи</Typography>
        {!isMobile && (
          <Button href="/users/create" color="primary" startIcon={<PlusIcon />}>
            Добавить пользователя
          </Button>
        )}
      </StyledHeader>
      <VgTable
        className={styles.table}
        onRowClick={(item) => navigate(`/users/${item.id}`)}
        data={users}
        columns={tableConfig}
        mobileMode={isMobile}
      />
    </div>
  )
}
