import React from 'react'
import { VgTableColumnType } from './VgTable'
import styles from './VgTable.module.css'

const isEmptyItem = <T,>(item: T | null | undefined | string | number | any[]): boolean => {
  return item === null || item === undefined || item === '' || (Array.isArray(item) && item.length === 0)
}

interface VgTableMobileItemProps<T> {
  item: T
  columns: VgTableColumnType<T>[]
  onRowClick?: (item: T) => void
  renderCollapsibleRow?: (item: T) => React.ReactNode
  strokeRow?: (item: T) => boolean
}

export const VgTableMobileItem = <T,>({ item, columns }: VgTableMobileItemProps<T>) => {
  return (
    <td className={styles.cell}>
      <div className={styles.mobileItem}>
        {columns.map((column, i) => (
          <div
            key={i}
            className={styles.mobileItem_wrapper}
            style={{
              gridArea: column?.gridArea?.join('/') || `${i + 1}/1/${i + 2}/3`,
              justifyItems: column.justifyItems
            }}
          >
            {!column.removeTitle && <div className={styles.mobileItem_header}>{column.title}</div>}
            <div className={styles.mobileItem_content} style={{ alignItems: column.alignItems }}>
              {column.render
                ? !isEmptyItem(item[column.key as keyof T])
                  ? column.render(column, item)
                  : '-'
                : String(item[column.key as keyof T]) || '-'}
            </div>
          </div>
        ))}
      </div>
    </td>
  )
}
