import { Box, debounce } from '@mui/material'
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useActions } from '../../../hooks/useActions'
import { useObserver } from '../../../hooks/useObserver'
import { roles } from '../../../types/userTypes'
import { useNavigate } from 'react-router-dom'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { StyledCell } from '../../../infrasturcture/theme/styled'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { Product, money } from '../../../types/productsTypes'
import { Loader } from '../../../components/Loader/Loader'
import { InputSearch } from '../../../components/Input/InputSearch'
import { ProductsFilter } from './ProductsFilter'
import styles from './ProductsContent.module.css'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

const productsColumns: VgTableColumnType<Product>[] = [
  {
    key: 'name',
    title: 'Товар',
    render: (_, product) => <ProductInfo product={product} />,
    width: '30%',
    justifyItems: 'normal'
  },
  {
    key: 'color',
    title: 'Цвет',
    render: (_, { color }) => <StyledCell>{color}</StyledCell>,
    width: '17.5%'
  },
  {
    key: 'manufacturer_name',
    title: 'Производитель',
    render: (_, item) => <StyledCell>{item.manufacturer_name}</StyledCell>,
    width: '17.5%'
  },
  {
    key: 'delivery_time',
    title: 'Время доставки',
    render: (_, item) => `${item.delivery_time} дн`,
    width: '17.5%'
  },
  {
    key: 'purchase_price',
    title: 'Розничная цена',
    render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
    width: '17.5%'
  }
]

export const ProductsContent: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const {
    getProductsThunkCreator,
    setProducts,
    setProduct,
    getSectionsThunkCreator,
    getManufacturersThunkCreator,
    searchProductThunkCreator
  } = useActions()
  const { user } = useTypeSelector((state) => state.user)
  const { products, searchProducts, count, limit, isLoaded, filters } = useTypeSelector((state) => state.products)

  const [page, setPage] = useState<number>(1)
  const [query, setQuery] = useState<string>('')

  const isFiltered = useRef<boolean>(false)
  const totalPages = Math.ceil(count / limit)
  const lastElement = useRef<HTMLDivElement | null>(null)

  const debouncedSearch = useCallback(
    debounce((search: string) => searchProductThunkCreator(search.trim(), filters), 800),
    [filters]
  )

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    debouncedSearch(e.target.value)
  }

  const isSearch = searchProducts.length > 0 && !!query

  useEffect(() => {
    isFiltered.current = true
    setProducts([])
    setPage(1)
  }, [filters])

  useEffect(() => {
    setProducts([])
    setProduct(null)
    getSectionsThunkCreator()
    if ([roles.ADMINISTRATOR, roles.SUPER_MANAGER].includes(user?.role)) {
      getManufacturersThunkCreator()
    }
  }, [])

  useEffect(() => {
    if (isFiltered.current && page > 1) return
    getProductsThunkCreator(limit, page, filters)
    isFiltered.current = false
  }, [page, filters])

  useObserver(lastElement, '100px', isLoaded, page < totalPages, () => {
    if (!isFiltered.current) setPage(page + 1)
  })

  return (
    <Box>
      <InputSearch onChange={handleSearch} value={query} placeholder="Введите название или код товара" />
      <ProductsFilter />
      <VgTable
        className={styles.table}
        onRowClick={(product) => navigate(`/products/${product.sku}`)}
        data={isSearch ? searchProducts : products}
        columns={productsColumns}
        mobileMode={isMobile}
      />
      <div
        style={{
          padding: '1px',
          display: query ? 'none' : 'block'
        }}
        ref={lastElement}
      ></div>
      {isLoaded && (
        <Loader
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '30px',
            height: '60vh'
          }}
        />
      )}
    </Box>
  )
}
