import React, { useEffect, useRef, useState } from 'react'
import { OperationsTab } from '../../../components/Operations/OperationsTab'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { useDoubleClick } from '../../../hooks/useDoubleClick'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useNavigate } from 'react-router-dom'
import { BookingRow } from './BookingRow'
import { Booking } from '../../../types/bookingsTypes'
import { MuiTabs } from '../../../components/MuiComponents/MuiTabs/MuiTabs'
import { StyledCell } from '../../../infrasturcture/theme/styled'
import { formatDate } from '../../../infrasturcture/utils/formatDate'
import { BitrixLink } from '../../../components/BitrixLink/BitrixLink'
import { OperationComment } from '../../../components/Operations/OperationComment'
import { useObserver } from '../../../hooks/useObserver'
import { Status } from '../../../components/Status/Status'
import { Option } from '../../../components/Options/Option'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'
import styles from '../../../styles/Operations.module.css'

const bookingsColumns: VgTableColumnType<Booking>[] = [
  {
    key: 'sku',
    title: 'Номер',
    width: '11%',
    gridArea: [1, 1, 2, 2]
  },
  {
    key: 'status',
    title: 'Статус',
    render: (_, { status }) => <Status type={status} wordBreak />,
    width: '13%',
    gridArea: [1, 2, 2, 3],
    justifyItems: 'right',
    removeTitle: true
  },
  {
    key: 'user_firstname',
    title: 'Пользователь',
    width: '15%',
    render: (_, item) => (
      <Option
        text={`${item.user_lastname || ''} ${item.user_firstname}`.trim()}
        subText={item.user_company}
        fontWeight={500}
        variant="table"
      />
    ),
    gridArea: [2, 1, 3, 3]
  },
  {
    key: 'created_at',
    title: 'Дата создания',
    render: (_, item) => <StyledCell>{formatDate(item.created_at)}</StyledCell>,
    width: '13%',
    gridArea: [6, 1, 7, 2]
  },
  {
    key: 'warehouse_name',
    title: 'Склад',
    width: '10%',
    gridArea: [3, 1, 4, 3]
  },
  {
    key: 'ready_at',
    title: 'Дата готовности',
    width: '14%',
    render: (_, item) => <StyledCell variant="green">{formatDate(item.ready_at)}</StyledCell>,
    gridArea: [6, 2, 7, 3],
    justifyItems: 'right'
  },
  {
    key: 'bitrix_id',
    title: 'Id B24',
    render: (_, item) => <BitrixLink bitrixId={item.bitrix_id} />,
    width: '8%',
    gridArea: [4, 1, 5, 3]
  },
  {
    key: 'comment',
    title: 'Комментарий',
    render: (_, { comment }) => <OperationComment>{comment}</OperationComment>,
    width: '16%',
    gridArea: [5, 1, 6, 3]
  }
]

export const BookingsTabs: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { booking, count, countQuickFilter, bookings, limit, filters, isLoaded } = useTypeSelector((state) => state.bookings)
  const { setBooking, getBookingThunkCreator, setBookings, setBookingFilters, getBookingsThunkCreator } = useActions()
  const [page, setPage] = useState<number>(1)

  const isFiltered = useRef<boolean>(false)
  const totalPages = Math.ceil((filters.quickFilter ? countQuickFilter : count) / limit)
  const lastElement = useRef<HTMLDivElement | null>(null)

  const handleClick = useDoubleClick(
    (id: string) => {
      if (id === String(booking?.sku)) return setBooking(null)
      getBookingThunkCreator(id, navigate)
    },
    (id: string) => navigate(`/bookings/${id}`)
  )

  const tabs = [
    {
      onClick: () => setBookingFilters({ ...filters, quickFilter: false }),
      label: (
        <OperationsTab count={count} variant="orange">
          Все бронирования
        </OperationsTab>
      ),
      content: (
        <VgTable
          onRowClick={(o) => handleClick(String(o.sku))}
          columns={bookingsColumns}
          data={bookings}
          renderCollapsibleRow={(b) => (b.id === booking?.id ? <BookingRow /> : undefined)}
          mobileMode={isMobile}
        />
      )
    },
    {
      onClick: () => setBookingFilters({ ...filters, quickFilter: true }),
      label: <OperationsTab count={countQuickFilter}>Активные бронирования</OperationsTab>,
      content: (
        <VgTable
          onRowClick={(o) => handleClick(String(o.sku))}
          columns={bookingsColumns}
          data={bookings}
          renderCollapsibleRow={(b) => (b.id === booking?.id ? <BookingRow /> : undefined)}
          mobileMode={isMobile}
        />
      )
    }
  ]

  useEffect(() => {
    isFiltered.current = true
    setBookings([])
    setPage(1)
  }, [filters])

  useEffect(() => {
    if (isFiltered.current && page > 1) return
    getBookingsThunkCreator(limit, page, filters)
    isFiltered.current = false
  }, [page, filters])

  useObserver(lastElement, '100px', isLoaded, page < totalPages, () => {
    if (!isFiltered.current) setPage(page + 1)
  })

  return (
    <>
      <MuiTabs className={styles.tabs} tabs={tabs} />
      <div style={{ padding: '1px' }} ref={lastElement}></div>
    </>
  )
}
