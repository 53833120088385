import React from 'react'
import { Breadcrumbs as MuiBreadcrumbs, Typography, styled } from '@mui/material'
import { useLocation, Link } from 'react-router-dom'
import { HomeIcon } from '../../infrasturcture/icons'
import { pathNames } from '../../infrasturcture/routes/routes'

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#ABABAB',
  textDecoration: 'none',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '18px',
  fill: 'currentcolor',
  '&:hover': {
    color: theme.palette.primary.main
  }
}))

interface BreadcrumbsProps {
  customText?: string | number
  customPaths?: { [key: string]: string }
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ customText, customPaths }) => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter(Boolean)

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{
        overflowX: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        mb: {
          xs: '32px',
          md: '39px'
        },
        lineHeight: '18px',
        '& .MuiBreadcrumbs-separator': {
          fontSize: '14px',
          fontWeight: 400
        },
        '& .MuiBreadcrumbs-ol': {
          whiteSpace: 'nowrap',
          flexWrap: 'nowrap'
        },
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}
    >
      <StyledLink to="">
        <HomeIcon style={{ marginRight: 8 }} />
        Домашняя страница
      </StyledLink>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`
        const pathname = customPaths ? customPaths[to] : pathNames[to]

        if (!pathname && !last) return null

        return last ? (
          <Typography key={to} color="primary.main" sx={{ fontSize: 14, fontWeight: 500, lineHeight: '18px' }}>
            {customText || pathname}
          </Typography>
        ) : (
          <StyledLink key={to} to={to}>
            {pathname}
          </StyledLink>
        )
      })}
    </MuiBreadcrumbs>
  )
}
