import React, { useEffect, useState } from 'react'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { FormProvider, useForm } from 'react-hook-form'
import { Form } from './ui/Form'
import { Button, IconButton, Typography } from '@mui/material'
import { DeleteIcon, PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { useActions } from '../../hooks/useActions'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'
import { warehousesSchema } from '../../infrasturcture/yupSchemes/warehousesSchema'
import styles from './Warehouses.module.css'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'

export const EditWarehouse: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const { deleteWarehouseThunkCreator, getWarehouseThunkCreator, editWarehouseThunkCreator, setWarehouse, setWarehousesErrors } =
    useActions()
  const { isLoaded, warehouses, warehouse, errors } = useTypeSelector((state) => state.warehouses)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isWarning, setIsWarning] = useState<boolean>(false)

  const methods = useForm({ resolver: yupResolver(warehousesSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    const { users, ...rest } = data
    return editWarehouseThunkCreator({ id, ...rest }, () => setIsEdit(false), navigate)
  }

  const deleteWarehouse = () => {
    deleteWarehouseThunkCreator(id!, () => navigate('/warehouses'), navigate)
    setIsWarning(false)
  }

  const handleEdit = () => setIsEdit(true)
  const handleWarning = () => setIsWarning(true)

  const handleFetch = async (id: string) => {
    const findWarehouse = warehouses?.find((w) => w.id === id)
    findWarehouse ? setWarehouse(findWarehouse) : getWarehouseThunkCreator(id, navigate)
  }

  useEffect(() => {
    handleFetch(id!)
  }, [])

  useEffect(() => {
    warehouse && methods.reset({ name: warehouse.name, region: warehouse.region || '', address: warehouse.address || '' })
  }, [warehouse])

  return (
    <div className={styles.container}>
      {!isLoaded && warehouse && (
        <>
          {isMobile && (
            <MobileHeader>
              {!warehouse?.isBooking && (
                <>
                  {isEdit ? (
                    <>
                      <SaveIcon onClick={methods.handleSubmit(onSubmit)} />
                      <DeleteIcon onClick={handleWarning} />
                    </>
                  ) : (
                    <PenIcon onClick={handleEdit} />
                  )}
                </>
              )}
            </MobileHeader>
          )}
          <Breadcrumbs customText={warehouse?.name} />
          <StyledHeader>
            <Typography variant="h1">{warehouse?.name}</Typography>
            {!isMobile && !warehouse?.isBooking && (
              <>
                {isEdit ? (
                  <>
                    <Button
                      onClick={methods.handleSubmit(onSubmit)}
                      disabled={methods.formState.isSubmitting}
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      Сохранить
                    </Button>
                    <Button onClick={handleWarning} color="error" startIcon={<DeleteIcon />}>
                      Удалить
                    </Button>
                  </>
                ) : (
                  <IconButton color="inherit" onClick={handleEdit}>
                    <PenIcon />
                  </IconButton>
                )}
              </>
            )}
          </StyledHeader>
          <FormProvider {...methods}>
            <Form type="edit" onSubmit={onSubmit} disabled={!isEdit} />
          </FormProvider>
        </>
      )}
      <SnacbarModal
        onAccept={deleteWarehouse}
        onCancel={() => setIsWarning(false)}
        open={isWarning}
        message={`Вы действительно хотите удалить склад '${warehouse?.name}'`}
      />
      <SnacbarModal isError isActions={false} onCancel={() => setWarehousesErrors({})} open={!!errors?.id} message={errors?.id} />
    </div>
  )
}
