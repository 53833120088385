import React from 'react'
import { OperationTitle } from '../../../components/Operations/OperationTitle'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { StyledCell } from '../../../infrasturcture/theme/styled'
import { money } from '../../../types/productsTypes'
import { Count } from '../../../components/Operations/Count'
import { OrderPosting, OrderPostingProduct } from '../../../types/ordersTypes'
import { Checkbox } from '../../../components/Checkbox/Checkbox'
import { useParams } from 'react-router-dom'
import styles from '../../../styles/OperationRow.module.css'
import { Box } from '@mui/material'
import { useOrdersPosting } from '../../../hooks/useOrdersPosting'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

interface OrderPostingRowProps {
  order: OrderPosting
}

export const OrderPostingRow: React.FC<OrderPostingRowProps> = ({ order }) => {
  const { isMobile } = useMediaQueryContext()
  const { type } = useParams()
  const TYPE = type?.toUpperCase()
  const { onChange, isAvailableToSelect } = useOrdersPosting(TYPE)

  const operationProductsColumns: VgTableColumnType<OrderPostingProduct>[] = [
    {
      key: 'id',
      title: '',
      render: (_, product) => {
        return (
          <Box sx={{ m: { xs: '-10px -10px 0 0', md: '-10px 0' } }}>
            <Checkbox
              onChange={() => onChange(product.order_id, product)}
              checked={product.selected}
              shakeOnClick={!isAvailableToSelect(product)}
              error={!isAvailableToSelect(product)}
            />
          </Box>
        )
      },
      width: '5%',
      style: { paddingLeft: 22 },
      gridArea: [1, 2, 2, 3],
      justifyItems: 'right',
      alignItems: 'start',
      removeTitle: true
    },
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} />,
      width: '25%',
      gridArea: [1, 1, 2, 2]
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '15%',
      gridArea: [2, 1, 3, 2]
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '15%',
      gridArea: [2, 2, 3, 3],
      justifyItems: 'right'
    },
    {
      key: 'delivery_time',
      title: 'Время доставки',
      render: (_, item) => `${item.delivery_time} дн`,
      width: '10%',
      textAlign: 'center',
      gridArea: [3, 1, 4, 3]
    },
    {
      key: 'count_left',
      title: 'Осталось оприходовать',
      render: (_, item) => <Count>{item.count_left}</Count>,
      textAlign: 'center',
      width: '15%',
      gridArea: [4, 1, 5, 2]
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, { count }) => <Count>{count}</Count>,
      textAlign: 'center',
      width: '15%',
      gridArea: [4, 2, 5, 3],
      justifyItems: 'right'
    }
  ]

  return (
    <tr className={styles.row}>
      <td className={styles.cell} colSpan={!isMobile ? 8 : undefined}>
        <div className={styles.wrapper}>
          <OperationTitle>Товары в заявке</OperationTitle>
          <VgTable
            className={styles.tableContainer}
            classNameRow={styles.tableRow}
            data={order.productsInOrder}
            columns={operationProductsColumns}
            mobileMode={isMobile}
          />
        </div>
      </td>
    </tr>
  )
}
