import React, { createContext, useContext, ReactNode } from 'react'
import { useMediaQuery } from '@mui/material'

interface MediaQueryContextType {
  isMobile: boolean
}

const MediaQueryContext = createContext<MediaQueryContextType | null>(null)

export const MediaQueryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useMediaQuery('(max-width: 1000px)')

  return <MediaQueryContext.Provider value={{ isMobile }}>{children}</MediaQueryContext.Provider>
}

export const useMediaQueryContext = () => {
  const context = useContext(MediaQueryContext)
  if (!context) {
    throw new Error('useMediaQueryContext must be used within a MediaQueryProvider')
  }
  return context
}
