import { Box, styled, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 400px)'
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '21px',
  textAlign: 'center',
  maxWidth: 447
}))

interface EmptyMessageProps {
  className?: string
}

export const EmptyMessage: React.FC<PropsWithChildren<EmptyMessageProps>> = ({ children, className }) => {
  return (
    <StyledBox className={className}>
      <StyledTypography variant="h2" color="text.primary">
        {children}
      </StyledTypography>
    </StyledBox>
  )
}
