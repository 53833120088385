import React, { useEffect } from 'react'
import styles from '../../styles/Operations.module.css'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, Typography } from '@mui/material'
import { SaveIcon } from '../../infrasturcture/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { operationTypes, operationTypesRus } from '../../types/operationsTypes'
import { FormProvider, useForm } from 'react-hook-form'
import { OperationForm } from './ui/OperationForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { createMovingOperactionSchema, createOperactionSchema } from '../../infrasturcture/yupSchemes/createOperactionSchema'
import { useActions } from '../../hooks/useActions'
import { OperationProducts } from './ui/OperationProducts'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

export const CreateOperation: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { type } = useParams()
  const navigate = useNavigate()
  const {
    getWarehousesThunkCreator,
    createOperationThunkCreator,
    setOrdersPosting,
    setOrdersPostingProducts,
    setOperationProducts,
    cleanOperationSearchProducts,
    setOperationFormValues,
    setOperationsErrors,
    setBookingsDone,
    setBookingsDoneProducts
  } = useActions()
  const { productsInOperation, formValues } = useTypeSelector((state) => state.operations)
  const { selectedOrdersPosting } = useTypeSelector((state) => state.orders)
  const { selectedBookingsDone } = useTypeSelector((state) => state.bookings)
  const { warehouses } = useTypeSelector((state) => state.warehouses)

  const TYPE = type?.toUpperCase() || operationTypes.POSTING
  const text = operationTypesRus[TYPE]

  const methods = useForm({
    resolver: yupResolver(TYPE === operationTypes.MOVING ? createMovingOperactionSchema : createOperactionSchema),
    mode: 'onChange',
    defaultValues: formValues
  })

  const onSubmit = (data: any) => {
    const warehouseFrom = warehouses.find((w) => w.id === data.warehouse_id_from)
    const warehouseTo = warehouses.find((w) => w.id === data.warehouse_id_to)

    const operation = {
      type: TYPE,
      warehouse_id_from: warehouseFrom?.id,
      warehouse_id_to: warehouseTo?.id || null,
      booking_warehouse_id:
        TYPE === operationTypes.MOVING ? warehouseTo?.booking_warehouse_id : warehouseFrom?.booking_warehouse_id,
      comment: data.comment || null,
      bitrix_id: data.bitrix_id || null,
      purpose: data.purpose || null,
      productsInOperation: productsInOperation.map((p) => {
        const countLeft =
          TYPE === operationTypes.WRITE_OFF
            ? selectedBookingsDone.reduce((acc, item) => (item.id === p.id ? acc + item.count : acc + 0), 0)
            : selectedOrdersPosting.reduce((acc, item) => (item.id === p.id ? acc + item.count : acc + 0), 0)
        return { product_id: p.id, order_id: null, count: p.count - countLeft }
      }),
      productsInOrdersPosting: selectedOrdersPosting.map((p) => ({
        products_audit_id: p.products_audit_id,
        product_id: p.id,
        order_id: p.order_id,
        count: p.count,
        count_left: p.count_left
      })),
      productsInBookingsDone: selectedBookingsDone.map((p) => ({
        products_audit_id: p.products_audit_id,
        product_id: p.id,
        order_id: p.order_id,
        count: p.count,
        write_off_left: p.write_off_left,
        isBooking: p.isBooking
      }))
    }

    return createOperationThunkCreator(
      operation,
      () => {
        setOperationProducts([])
        TYPE !== 'POSTING' && cleanOperationSearchProducts([])
        if (TYPE === 'WRITE_OFF') {
          setBookingsDone([])
          setBookingsDoneProducts([])
        }
        setOrdersPosting([])
        setOrdersPostingProducts([])
        setOperationsErrors({})
        setOperationFormValues({})
        methods.reset({ bitrix_id: '', comment: '', purpose: '', warehouse_id_from: '', warehouse_id_to: '' })
      },
      navigate
    )
  }

  useEffect(() => {
    getWarehousesThunkCreator({ isBooking: false })
  }, [])

  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileHeader>
          <SaveIcon onClick={methods.handleSubmit(onSubmit)} />
        </MobileHeader>
      )}
      <Breadcrumbs customText={text} />
      <StyledHeader>
        <Typography variant="h1">{text}</Typography>
        {!isMobile && (
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            disabled={methods.formState.isSubmitting}
            color="primary"
            startIcon={<SaveIcon />}
          >
            Сохранить
          </Button>
        )}
      </StyledHeader>
      <FormProvider {...methods}>
        <OperationForm type="create" operationType={TYPE} onSubmit={onSubmit} />
      </FormProvider>
      <OperationProducts type="create" />
    </div>
  )
}
