import React from 'react'
import styles from './VgTable.module.css'
import { VgTableThead } from './VgTableThead'
import { VgTableTbody } from './VgTableTbody'
import clsx from 'clsx'

export interface CellProps {
  width?: number | string
  textAlign?: 'left' | 'center' | 'right'
  justifyItems?: 'left' | 'center' | 'right' | 'normal'
  alignItems?: 'start' | 'center' | 'end'
  style?: React.CSSProperties
  gridArea?: [rowStart: number, colStart: number, rowEnd: number, colEnd: number]
  removeTitle?: boolean
}

export interface VgTableColumnType<T> extends CellProps {
  key: keyof T
  title: string
  render?: (column: VgTableColumnType<T>, item: T) => React.ReactNode
}

interface VgTableProps<T> {
  data: T[]
  columns: VgTableColumnType<T>[]
  className?: string
  classNameTable?: string
  classNameRow?: string
  onRowClick?: (item: T) => void
  renderCollapsibleRow?: (item: T) => React.ReactNode
  strokeRow?: (item: T) => boolean
  mobileMode?: boolean
}

export const VgTable = <T,>({
  data,
  columns,
  className,
  classNameTable,
  onRowClick,
  renderCollapsibleRow,
  strokeRow,
  classNameRow,
  mobileMode = false
}: VgTableProps<T>) => {
  return (
    <div className={clsx(styles.container, className)}>
      <table className={clsx(styles.table, classNameTable)}>
        <VgTableThead columns={columns} />
        <tbody className={styles.emptyBody}></tbody>
        <VgTableTbody
          classNameRow={classNameRow}
          data={data}
          columns={columns}
          onRowClick={onRowClick}
          renderCollapsibleRow={renderCollapsibleRow}
          strokeRow={strokeRow}
          mobileMode={mobileMode}
        />
      </table>
    </div>
  )
}
