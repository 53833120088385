import React, { useState } from 'react'
import { MuiTabs } from '../../../components/MuiComponents/MuiTabs/MuiTabs'
import { BookingDoneRow } from './BookingDoneRow'
import { Status } from '../../../components/Status/Status'
import { Checkbox } from '../../../components/Checkbox/Checkbox'
import { Box } from '@mui/material'
import { StyledCell, StyledLinkRouter } from '../../../infrasturcture/theme/styled'
import { formatDate } from '../../../infrasturcture/utils/formatDate'
import { BitrixLink } from '../../../components/BitrixLink/BitrixLink'
import { OperationComment } from '../../../components/Operations/OperationComment'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useDoubleClick } from '../../../hooks/useDoubleClick'
import { BookingDone, BookingDoneProduct } from '../../../types/bookingsTypes'
import { Option } from '../../../components/Options/Option'
import { Count } from '../../../components/Operations/Count'
import { money } from '../../../types/productsTypes'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { useSearchParams } from 'react-router-dom'
import { EmptyMessage } from '../../../components/EmptyMessage/EmptyMessage'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

export const BookingsDoneTabs: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const [searchParams] = useSearchParams()
  const productId = searchParams.get('productId')
  const { setBookingsDone } = useActions()
  const { bookingsDone } = useTypeSelector((state) => state.bookings)

  const [selectedId, setSelectedId] = useState<string | null>(null)

  const products = bookingsDone.flatMap((p) => [...p.productsInBooking, ...p.productsInOrder]).filter((p) => p.id === productId)

  const handleClick = useDoubleClick(
    (id: string) => setSelectedId((prev) => (prev === id ? null : id)),
    () => undefined
  )

  const bookingDoneColumns: VgTableColumnType<BookingDone>[] = [
    {
      key: 'id',
      title: '',
      width: '4%',
      style: { paddingLeft: 22 },
      render: (_, { productsInOrder, productsInBooking, id }) => {
        const checked = productsInBooking.concat(productsInOrder).every((p) => p.selected)

        /*   const isExist = productsInOrder.every((p) => {
          const availableCount = stateProducts.find((item) => item.id === p.id)?.count || 0
          const operationProductCount = productsInOperation.find((item) => item.id === p.id)?.count || 0
          return p.count + operationProductCount <= availableCount
        }) */

        return (
          <Box sx={{ m: { xs: '-10px -10px 0 0', md: '-10px 0' } }}>
            <Checkbox
              /* onClick={() => !isExist && setOrdersErrors({ ordersPosting: 'Необходимые на складе товары отсутствуют' })} */
              onChange={() =>
                setBookingsDone(
                  bookingsDone.map((o) =>
                    o.id === id
                      ? {
                          ...o,
                          productsInBooking: o.productsInBooking.map((p) => ({ ...p, selected: !checked })),
                          productsInOrder: o.productsInOrder.map((p) => ({ ...p, selected: !checked }))
                        }
                      : o
                  )
                )
              }
              value={id}
              checked={checked}
              /* shakeOnClick={!isExist}
              disabled={!isExist}
              error={!isExist} */
            />
          </Box>
        )
      },
      gridArea: [1, 2, 2, 3],
      justifyItems: 'right',
      alignItems: 'start',
      removeTitle: true
    },
    {
      key: 'sku',
      title: 'Номер',
      width: '10%',
      gridArea: [1, 1, 2, 2]
    },
    {
      key: 'status',
      title: 'Статус',
      render: (_, { status }) => <Status type={status} wordBreak />,
      width: '12%',
      gridArea: [2, 1, 3, 3]
    },
    {
      key: 'user_firstname',
      title: 'Пользователь',
      width: '15%',
      render: (_, item) => (
        <Option
          text={`${item.user_lastname || ''} ${item.user_firstname}`.trim()}
          subText={item.user_company}
          fontWeight={500}
          variant="table"
        />
      ),
      gridArea: [3, 1, 4, 3]
    },
    {
      key: 'created_at',
      title: 'Дата создания',
      render: (_, item) => <StyledCell>{formatDate(item.created_at)}</StyledCell>,
      width: '12%',
      gridArea: [7, 1, 8, 2]
    },
    {
      key: 'warehouse_name',
      title: 'Склад',
      width: '10%',
      gridArea: [4, 1, 5, 3]
    },
    {
      key: 'bitrix_id',
      title: 'Id B24',
      render: (_, item) => <BitrixLink bitrixId={item.bitrix_id} />,
      width: '7%',
      gridArea: [5, 1, 6, 3]
    },
    {
      key: 'ready_at',
      title: 'Дата готовности',
      width: '13%',
      render: (_, item) => <StyledCell variant="green">{item.ready_at}</StyledCell>,
      gridArea: [7, 2, 8, 3],
      justifyItems: 'right'
    },
    {
      key: 'comment',
      title: 'Комментарий',
      render: (_, { comment }) => <OperationComment>{comment}</OperationComment>,
      width: '17%',
      gridArea: [6, 1, 7, 3]
    }
  ]

  const productsColumns: VgTableColumnType<BookingDoneProduct>[] = [
    {
      key: 'id',
      title: '',
      render: (_, product) => {
        /* const availableCount = products.find((item) => item.id === product.id)?.count || 0
          const bookingProductCount = productsInOperation.find((item) => item.id === product.id)?.count || 0
          const isExist = availableCount - (1 + bookingProductCount) >= 0 */

        return (
          <Box sx={{ m: { xs: '-10px -10px 0 0', md: '-10px 0' } }}>
            <Checkbox
              /*  onClick={() => !isExist && setBookingsErrors({ bookingsDone: 'Необходимые на складе товары отсутствуют' })} */
              onChange={() =>
                setBookingsDone(
                  bookingsDone.map((b) =>
                    b.id === product.order_id
                      ? {
                          ...b,
                          [product.isBooking ? 'productsInBooking' : 'productsInOrder']: b[
                            product.isBooking ? 'productsInBooking' : 'productsInOrder'
                          ].map((p) => (p.id === product.id ? { ...p, selected: !product.selected } : p))
                        }
                      : b
                  )
                )
              }
              checked={product.selected}
              /*   shakeOnClick={!isExist}
                disabled={!isExist || product.count_left === 0}
                error={!isExist} */
            />
          </Box>
        )
      },
      width: '5%',
      style: { paddingLeft: 22 },
      gridArea: [1, 2, 2, 3],
      justifyItems: 'right',
      alignItems: 'start',
      removeTitle: true
    },
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} />,
      width: '25%',
      gridArea: [1, 1, 2, 2]
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '10%',
      gridArea: [2, 1, 3, 2]
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '11%',
      gridArea: [2, 2, 3, 3],
      justifyItems: 'right'
    },
    {
      key: 'delivery_time',
      title: 'Время доставки',
      render: (_, item) => `${item.delivery_time} дн`,
      width: '11%',
      textAlign: 'center',
      gridArea: [3, 1, 4, 2]
    },
    {
      key: 'order_sku',
      title: 'Номер бронирования',
      render: (_, item) => (
        <StyledLinkRouter target="_blank" to={`/bookings/${item.order_sku}`}>
          {item.order_sku}
        </StyledLinkRouter>
      ),
      width: '14%',
      textAlign: 'center',
      gridArea: [3, 2, 4, 3],
      justifyItems: 'right'
    },
    {
      key: 'count_left',
      title: 'Осталось списать',
      render: (_, item) => <Count>{item.write_off_left}</Count>,
      textAlign: 'center',
      width: '10%',
      gridArea: [4, 1, 5, 2]
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, { count }) => <Count>{count}</Count>,
      textAlign: 'center',
      width: '14%',
      gridArea: [4, 2, 5, 3],
      justifyItems: 'right'
    }
  ]

  const tabs = [
    {
      label: 'По бронированиям',
      content:
        bookingsDone.length > 0 ? (
          <VgTable
            onRowClick={(booking) => handleClick(booking.id)}
            columns={bookingDoneColumns}
            data={bookingsDone}
            renderCollapsibleRow={(booking) => (booking.id === selectedId ? <BookingDoneRow booking={booking} /> : undefined)}
            mobileMode={isMobile}
          />
        ) : (
          <EmptyMessage>Бронирования для списания отсутствуют</EmptyMessage>
        )
    },
    {
      label: 'По товару',
      content:
        products.length > 0 ? (
          <VgTable columns={productsColumns} data={products} mobileMode={isMobile} />
        ) : (
          <EmptyMessage>
            Выбранный товар для списания отсутствует. Для данного раздела настроен авторедирект. Здесь показывается только тот
            товар, который был введен в поиске при оформлении операции.
          </EmptyMessage>
        )
    }
  ]

  return <MuiTabs tabs={tabs} index={productId ? 1 : undefined} />
}
