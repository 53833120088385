import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import { store } from './store'
import { ThemeProvider } from '@mui/material'
import { theme } from './infrasturcture/theme/theme'
import { MediaQueryProvider } from './contexts/MediaQueryContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MediaQueryProvider>
        <App />
      </MediaQueryProvider>
    </ThemeProvider>
  </Provider>
)
