import React, { useEffect } from 'react'
import { ProductForm } from './ui/ProductForm'
import { useActions } from '../../hooks/useActions'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createProductSchema } from '../../infrasturcture/yupSchemes/createProductSchema'
import { useFileReader } from '../../hooks/useFileReader'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { roles } from '../../types/userTypes'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, Typography } from '@mui/material'
import { SaveIcon } from '../../infrasturcture/icons'
import { useNavigate } from 'react-router-dom'
import styles from './CreateProduct.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

export const CreateProduct: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { getSectionsThunkCreator, getManufacturersThunkCreator, createProductThunkCreator } = useActions()
  const { user } = useTypeSelector((state) => state.user)
  const { sections } = useTypeSelector((state) => state.sections)
  const { manufacturers } = useTypeSelector((state) => state.manufacturers)
  const fileReader = useFileReader()

  const methods = useForm({ resolver: yupResolver(createProductSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    return createProductThunkCreator(
      {
        ...data,
        section_name: sections.find((s) => s.id === data.section_id)?.name,
        manufacturer_name: manufacturers.find((m) => m.id === data.manufacturer_id)?.name || null
      },
      () => {
        methods.reset()
        fileReader.setImage(null)
      },
      navigate
    )
  }

  useEffect(() => {
    !sections.length && getSectionsThunkCreator()

    if (!manufacturers.length && [roles.ADMINISTRATOR, roles.SUPER_MANAGER].includes(user?.role)) {
      getManufacturersThunkCreator()
    }
  }, [])

  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileHeader>
          <SaveIcon onClick={methods.handleSubmit(onSubmit)} />
        </MobileHeader>
      )}
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Новый товар</Typography>
        {!isMobile && (
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            disabled={methods.formState.isSubmitting}
            color="primary"
            startIcon={<SaveIcon />}
          >
            Сохранить
          </Button>
        )}
      </StyledHeader>
      <FormProvider {...methods}>
        <ProductForm type="create" onSubmit={onSubmit} fileReader={fileReader} />
      </FormProvider>
    </div>
  )
}
