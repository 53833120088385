import React, { useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { VgTable, VgTableColumnType } from '../../components/VgTable/VgTable'
import { useNavigate } from 'react-router-dom'
import { Manufacturer } from '../../types/manufacturersTypes'
import { PlusIcon } from '../../infrasturcture/icons'
import styles from './Manufacturers.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

const manufacturersColumns: VgTableColumnType<Manufacturer>[] = [
  {
    key: 'name',
    title: 'Название'
  }
]

export const Manufacturers: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { manufacturers } = useTypeSelector((state) => state.manufacturers)
  const { getManufacturersThunkCreator } = useActions()

  useEffect(() => {
    getManufacturersThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileHeader>
          <PlusIcon onClick={() => navigate('/products/manufacturers/create')} />
        </MobileHeader>
      )}
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Производители</Typography>
        {!isMobile && (
          <Button href="/products/manufacturers/create" color="primary" startIcon={<PlusIcon />}>
            Добавить производителя
          </Button>
        )}
      </StyledHeader>
      <VgTable
        className={styles.table}
        onRowClick={(item) => navigate(`/products/manufacturers/${item.id}`)}
        data={manufacturers}
        columns={manufacturersColumns}
        mobileMode={isMobile}
      />
    </div>
  )
}
