import React from 'react'
import { Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { OperationsButton } from './ui/OperationsButton'
import { OperationsContent } from './ui/OperationsContent'
import styles from '../../styles/Operations.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

export const Operations: React.FC = () => {
  const { isMobile } = useMediaQueryContext()

  return (
    <div className={styles.container}>
      {isMobile && <MobileHeader />}
      <Breadcrumbs />
      <StyledHeader
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'normal' },
          gap: { xs: '12px' },
          mb: { xs: '32px', md: '40px' }
        }}
      >
        <Typography variant="h1">Операции</Typography>
        <OperationsButton />
      </StyledHeader>
      <OperationsContent />
    </div>
  )
}
