import { DefaultLayoutAction, DefaultLayoutActionTypes, DefaultLayoutState } from '../../types/layoutTypes'

const initialState: DefaultLayoutState = {
  isMenuOpen: true,
  isVisible: true,
  lastScrollY: 0
}

export const layoutReducer = (state = initialState, action: DefaultLayoutAction): DefaultLayoutState => {
  switch (action.type) {
    case DefaultLayoutActionTypes.SET_IS_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: action.payload
      }
    case DefaultLayoutActionTypes.SET_IS_VISIBLE:
      return {
        ...state,
        isVisible: action.payload
      }
    case DefaultLayoutActionTypes.SET_LAST_SCROLL_Y:
      return {
        ...state,
        lastScrollY: action.payload
      }
    default:
      return state
  }
}
