import React, { PropsWithChildren } from 'react'
import { BurgerMenuIcon } from '../../infrasturcture/icons'
import { ReactComponent as BasaltText } from '../../assets/images/basalt-text.svg'
import clsx from 'clsx'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import styles from './MobileHeader.module.css'

export const MobileHeader: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { isMenuOpen, isVisible } = useTypeSelector((state) => state.layout)
  const { setIsMenuOpen } = useActions()

  const toggleIsOpen = () => setIsMenuOpen(!isMenuOpen)

  return (
    <div className={clsx(styles.container, { [styles.visible]: isVisible, [styles.hidden]: !isVisible })}>
      <div className={styles.leftPanel}>
        <BurgerMenuIcon onClick={toggleIsOpen} />
      </div>
      <BasaltText />
      <div className={styles.rightPanel}>{children}</div>
    </div>
  )
}
