import React from 'react'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { OperationTitle } from '../../../components/Operations/OperationTitle'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { ProductInOperation, operationTypes } from '../../../types/operationsTypes'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { StyledCell, StyledLinkRouter } from '../../../infrasturcture/theme/styled'
import { money } from '../../../types/productsTypes'
import { Count } from '../../../components/Operations/Count'
import styles from '../../../styles/OperationRow.module.css'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

export const OperationRow: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { operation } = useTypeSelector((state) => state.operations)

  const operationProductsColumns: VgTableColumnType<ProductInOperation>[] = [
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} />,
      width: '25%',
      gridArea: [1, 1, 2, 3],
      justifyItems: 'normal'
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '15%',
      gridArea: [2, 1, 3, 2]
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '20%',
      gridArea: [2, 2, 3, 3],
      justifyItems: 'right'
    },
    {
      key: 'manufacturer_name',
      title: 'Производитель',
      render: (_, item) => <StyledCell>{item.manufacturer_name}</StyledCell>,
      width: '20%',
      gridArea: [3, 1, 4, 2]
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, { count }) => <Count>{count}</Count>,
      textAlign: 'center',
      width: '20%',
      gridArea: [3, 2, 4, 3],
      justifyItems: 'right'
    }
  ]

  const subOperationProductsColumns: VgTableColumnType<ProductInOperation>[] = [
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} />,
      width: '22%',
      gridArea: [1, 1, 2, 3],
      justifyItems: 'normal'
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '15%',
      gridArea: [2, 1, 3, 2]
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '15%',
      gridArea: [2, 2, 3, 3],
      justifyItems: 'right'
    },
    {
      key: 'order_sku',
      title: operation?.type === operationTypes.WRITE_OFF ? 'Номер бронирования' : 'Номер заявки',
      render: (_, item) => (
        <StyledLinkRouter
          target="_blank"
          to={`/${operation?.type === operationTypes.WRITE_OFF ? 'bookings' : 'orders'}/${item.order_sku}`}
        >
          {item.order_sku}
        </StyledLinkRouter>
      ),
      width: '13%',
      textAlign: 'center',
      gridArea: [3, 1, 4, 3]
    },
    {
      key: 'count_left',
      title: operation?.type === operationTypes.WRITE_OFF ? 'Осталось списать' : 'Осталось оприходовать',
      render: (_, item) => <Count>{operation?.type === operationTypes.WRITE_OFF ? item.write_off_left : item.count_left}</Count>,
      textAlign: 'center',
      width: '15%',
      gridArea: [4, 1, 5, 2]
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, { count }) => <Count>{count}</Count>,
      textAlign: 'center',
      width: '20%',
      gridArea: [4, 2, 5, 3],
      justifyItems: 'right'
    }
  ]

  return (
    <>
      {operation && (
        <tr className={styles.row}>
          <td className={styles.cell} colSpan={!isMobile ? 7 : undefined}>
            <div className={styles.wrapper}>
              <OperationTitle isShowTooltip>Товары в операции</OperationTitle>
              <VgTable
                classNameRow={styles.tableRow}
                data={operation?.productsInOperation || []}
                columns={operationProductsColumns}
                mobileMode={isMobile}
              />
            </div>
            {!!operation?.productsInOrdersPosting?.length && (
              <div className={styles.wrapper}>
                <OperationTitle>
                  {operation.type === operationTypes.WRITE_OFF ? 'Товары под бронирование' : 'Товары под заявки'}
                </OperationTitle>
                <VgTable
                  classNameRow={styles.tableRow}
                  data={operation.productsInOrdersPosting}
                  columns={subOperationProductsColumns}
                  strokeRow={(item) =>
                    operation.type === operationTypes.WRITE_OFF ? item.write_off_left === 0 : item.count_left === 0
                  }
                  mobileMode={isMobile}
                />
              </div>
            )}
          </td>
        </tr>
      )}
    </>
  )
}
