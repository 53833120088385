import React from 'react'
import { VgTableColumnType } from './VgTable'
import clsx from 'clsx'
import styles from './VgTable.module.css'
import { VgTableMobileItem } from './VgTableMobileItem'

interface VgTableTbodyProps<T> {
  data: T[]
  columns: VgTableColumnType<T>[]
  classNameRow?: string
  onRowClick?: (item: T) => void
  renderCollapsibleRow?: (item: T) => React.ReactNode
  strokeRow?: (item: T) => boolean
  mobileMode?: boolean
}

export const VgTableTbody = <T,>({
  data,
  columns,
  classNameRow,
  onRowClick,
  renderCollapsibleRow,
  strokeRow,
  mobileMode = false
}: VgTableTbodyProps<T>) => {
  const handleClick = (item: T) => {
    onRowClick && onRowClick(item)
  }

  return (
    <tbody className={styles.tbody}>
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <tr
            className={clsx(styles.row, classNameRow, { [styles.strokeRow]: strokeRow?.(item) })}
            onClick={() => handleClick(item)}
          >
            {mobileMode ? (
              <VgTableMobileItem item={item} columns={columns} />
            ) : (
              columns.map((column, columnIndex) => (
                <td
                  key={columnIndex}
                  className={clsx(styles.cell, styles.alignLeft)}
                  style={{ width: column?.width, textAlign: column?.textAlign, ...column?.style }}
                >
                  {column.render ? column.render(column, item) : String(item[column.key as keyof T])}
                </td>
              ))
            )}
          </tr>
          {renderCollapsibleRow && renderCollapsibleRow(item)}
        </React.Fragment>
      ))}
    </tbody>
  )
}
