import { OperationsState, OperationsAction, OperationsActionTypes } from '../../types/operationsTypes'

const initialFormValues = {
  bitrix_id: '',
  comment: '',
  purpose: '',
  warehouse_id_from: '',
  warehouse_id_to: ''
}

const initialState: OperationsState = {
  operations: [],
  operation: null,
  managers: [],
  products: [],
  productsInOperation: [],
  limit: 50,
  count: 0,
  countQuickFilter: 0,
  isLoaded: false,
  formValues: initialFormValues,
  errors: {},
  filters: {
    type: null,
    managers: [],
    products: [],
    warehouses: [],
    startDate: null,
    endDate: null,
    sku: null,
    quickFilter: false
  }
}

export const operationsReducer = (state = initialState, action: OperationsAction): OperationsState => {
  switch (action.type) {
    case OperationsActionTypes.GET_OPERATIONS:
      return {
        ...state,
        isLoaded: false,
        count: action.payload.count,
        countQuickFilter: action.payload.countQuickFilter,
        operations: [...state.operations, ...action.payload.rows]
      }
    case OperationsActionTypes.GET_OPERATION:
      return {
        ...state,
        isLoaded: false,
        operation: action.payload
      }
    case OperationsActionTypes.GET_MANAGERS:
      return {
        ...state,
        managers: action.payload
      }
    case OperationsActionTypes.GET_OPERATION_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case OperationsActionTypes.SET_OPERATION_PRODUCTS:
      return {
        ...state,
        productsInOperation: action.payload
      }
    case OperationsActionTypes.ADD_PRODUCT:
      return {
        ...state,
        productsInOperation: [...state.productsInOperation, action.payload]
      }
    case OperationsActionTypes.ADD_PRODUCTS_OPERATION:
      return {
        ...state,
        productsInOperation: action.payload
      }
    case OperationsActionTypes.PLUS_PRODUCT:
      return {
        ...state,
        productsInOperation: state.productsInOperation
          .map((item) => (item.id === action.payload.id ? { ...item, count: item.count + action.payload.count } : item))
          .filter((item) => item.count > 0)
      }
    case OperationsActionTypes.MINUS_PRODUCT:
      return {
        ...state,
        productsInOperation: state.productsInOperation
          .map((item) => (item.id === action.payload ? { ...item, count: item.count - 1 } : item))
          .filter((item) => item.count > 0)
      }
    case OperationsActionTypes.SET_OPERATIONS:
      return {
        ...state,
        operations: action.payload
      }
    case OperationsActionTypes.CLEAN_OPERATION_SEARCH_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case OperationsActionTypes.SET_OPERATION_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }
    case OperationsActionTypes.SET_OPERATION:
      return {
        ...state,
        operation: action.payload
      }
    case OperationsActionTypes.SET_OPERATION_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      }
    case OperationsActionTypes.SET_OPERATION_FORM_VALUES:
      return {
        ...state,
        formValues: Object.keys(action.payload).length === 0 ? initialFormValues : { ...state.formValues, ...action.payload }
      }
    case OperationsActionTypes.SET_OPERATIONS_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}
