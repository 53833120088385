import React, { PropsWithChildren } from 'react'
import styles from './Label.module.css'
import clsx from 'clsx'

interface LabelRowProps extends PropsWithChildren<unknown> {
  style?: React.CSSProperties
  className?: string
}

export const LabelRow: React.FC<LabelRowProps> = ({ children, style, className }) => {
  return (
    <div className={clsx(styles.row, className)} style={style}>
      {children}
    </div>
  )
}
