import { BalancesState, BalancesAction, BalancesActionsTypes } from '../../types/balancesTypes'

const initialState: BalancesState = {
  balances: [],
  supplierBalances: [],
  warehouses: [],
  isLoaded: false,
  filters: { date: null, manufacturers: [], search: null, notNull: false, mainWarehouses: false },
  supplierFilters: { manufacturers: [], search: null, notNull: false }
}

export const balancesReducer = (state = initialState, action: BalancesAction): BalancesState => {
  switch (action.type) {
    case BalancesActionsTypes.GET_BALANCES:
      return {
        ...state,
        balances: action.payload.balances,
        warehouses: action.payload.warehouses,
        isLoaded: false
      }
    case BalancesActionsTypes.GET_SUPPLIER_BALANCES:
      return {
        ...state,
        supplierBalances: action.payload.supplierBalances,
        warehouses: action.payload.warehouses,
        isLoaded: false
      }
    case BalancesActionsTypes.SET_BALANCES_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }
    case BalancesActionsTypes.SET_BALANCES:
      return {
        ...state,
        balances: action.payload
      }
    case BalancesActionsTypes.SET_SUPPLIER_BALANCES:
      return {
        ...state,
        supplierBalances: action.payload
      }
    case BalancesActionsTypes.SET_BALANCES_WAREHOUSES:
      return {
        ...state,
        warehouses: action.payload
      }
    case BalancesActionsTypes.SET_BALANCE_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      }
    case BalancesActionsTypes.SET_SUPPLIER_FILTERS:
      return {
        ...state,
        supplierFilters: { ...state.filters, ...action.payload }
      }
    default:
      return state
  }
}
