import { ErrorMessages } from './errorTypes'
import { Section } from './sectionsTypes'

/* eslint-disable no-unused-vars */
export interface Product {
  id: string
  sku: string
  name: string
  color: string
  delivery_time: number
  purchase_price: number | null
  retail_price: number | null
  photo: string | null
  name_1c: string | null
  description: string | null
  description_custom: string | null
  custom: boolean
  subsection: string | null
  section_id: string
  section_name: string
  manufacturer_id: string | null
  manufacturer_name: string | null
}

export const money: { [key in 'RUB']: string } = {
  RUB: '\u20BD'
}

export interface ProductFilters {
  section: Omit<Section, 'users'> | null
  subsections: string[]
  custom: boolean
}

export interface ProductsState {
  products: Product[]
  product: Product | null
  count: number
  limit: number
  searchProducts: Product[]
  isLoaded: boolean
  errors: ErrorMessages
  filters: ProductFilters
}

export enum ProductsActionTypes {
  GET_PRODUCTS = 'GET_PRODUCTS',
  GET_PRODUCT = 'GET_PRODUCT',
  SET_PRODUCT = 'SET_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  SET_PRODUCT_FILTERS = 'SET_PRODUCT_FILTERS',
  SEARCH_PRODUCTS = 'SEARCH_PRODUCT',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_LOADED = 'SET_LOADED',
  SET_PRODUCTS_ERRORS = 'SET_PRODUCTS_ERRORS',
  DELETE_PRODUCT = 'DELETE_PRODUCT'
}

export interface GetProductsThunkCreator {
  type: ProductsActionTypes.GET_PRODUCTS
  payload: { count: number; rows: Product[] }
}

export interface GetProductThunkCreator {
  type: ProductsActionTypes.GET_PRODUCT
  payload: Product | null
}

export interface UpdateProductThunkCreator {
  type: ProductsActionTypes.UPDATE_PRODUCT
  payload: Product
}

export interface SetProductAction {
  type: ProductsActionTypes.SET_PRODUCT
  payload: Product | null
}

export interface SetProductFiltersAction {
  type: ProductsActionTypes.SET_PRODUCT_FILTERS
  payload: Partial<ProductFilters>
}

export interface SeacrhProductsThunkCreator {
  type: ProductsActionTypes.SEARCH_PRODUCTS
  payload: Product[]
}

export interface SetProductsAction {
  type: ProductsActionTypes.SET_PRODUCTS
  payload: Product[]
}

export interface SetProductLoaded {
  type: ProductsActionTypes.SET_LOADED
  payload: boolean
}

export interface SetProductsErrorsAction {
  type: ProductsActionTypes.SET_PRODUCTS_ERRORS
  payload: ErrorMessages
}

export interface DeleteProductAction {
  type: ProductsActionTypes.DELETE_PRODUCT
  payload: string
}

export type ProductsAction =
  | GetProductsThunkCreator
  | GetProductThunkCreator
  | UpdateProductThunkCreator
  | SetProductsAction
  | SetProductLoaded
  | SeacrhProductsThunkCreator
  | DeleteProductAction
  | SetProductAction
  | SetProductFiltersAction
  | SetProductsErrorsAction
