import React, { MouseEvent, useState } from 'react'
import { CustomTooltipProps, defaultText } from './tooltipConfig'
import { MuiTooltip } from './MuiTooltip'
import { InfoIcon } from '../../../infrasturcture/icons'
import styles from './CustomTooltip.module.css'
import clsx from 'clsx'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ text, label, className }) => {
  const { isMobile } = useMediaQueryContext()
  const tooltipText = text || defaultText

  const [open, setOpen] = useState<boolean>(false)

  const handleTooltipOpen = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (!open) {
      setOpen(true)
      setTimeout(() => setOpen(false), 3000)
    }
  }

  return isMobile ? (
    <div className={clsx(styles.container, className)} onClick={handleTooltipOpen}>
      <MuiTooltip
        title={tooltipText}
        placement="bottom-end"
        PopperProps={{
          disablePortal: true,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [15, -20]
              }
            }
          ]
        }}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <label className={styles.label}>
          {label}
          <InfoIcon className={styles.icon} />
        </label>
      </MuiTooltip>
    </div>
  ) : (
    <div className={clsx(styles.container, className)}>
      <label className={styles.label}>{label}</label>
      <MuiTooltip
        title={tooltipText}
        leaveDelay={500}
        placement="right-end"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -15]
              }
            }
          ]
        }}
      >
        <InfoIcon className={styles.icon} />
      </MuiTooltip>
    </div>
  )
}
