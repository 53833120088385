import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../../infrasturcture/theme/styled'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { PenIcon, SaveIcon } from '../../../infrasturcture/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { Search } from '../../../components/Search/Search'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { warehouseAccessSchema } from '../../../infrasturcture/yupSchemes/warehousesSchema'
import { Users } from '../../../types/usersTypes'
import { rolesRus } from '../../../types/userTypes'
import { OptionInfo } from '../../../components/Options/OptionInfo'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'
import { MobileHeader } from '../../../components/MobileHeader/MobileHeader'
import styles from '../../../styles/Form.module.css'

export const WarehouseForm: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { id } = useParams()
  const navigate = useNavigate()

  const { getWarehouseThunkCreator, getWarehousesThunkCreator, getUsersThunkCreator, updateWarehouseAccessThunkCreator } =
    useActions()

  const { isLoaded, warehouses, warehouse } = useTypeSelector((state) => state.warehouses)
  const { users } = useTypeSelector((state) => state.users)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const optionsUsers = users?.map((u) => ({ label: u.lastname ? `${u.lastname} ${u.firstname}` : u.firstname, value: u }))

  const { handleSubmit, reset, control } = useForm({ resolver: yupResolver(warehouseAccessSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    return updateWarehouseAccessThunkCreator({ warehouseId: id!, customers: data?.users.map((u: Users) => u.id) }, () =>
      setIsEdit(false)
    )
  }

  const handleEdit = () => setIsEdit(true)

  const handleFetch = async (id: string) => {
    !users.length && (await getUsersThunkCreator())
    !warehouses.length && (await getWarehousesThunkCreator({ isBooking: false }))
    getWarehouseThunkCreator(id, navigate)
  }

  useEffect(() => {
    handleFetch(id!)
  }, [])

  useEffect(() => {
    if (warehouse) {
      reset({ users: users.filter((u) => warehouse?.users?.includes(u.id)) })
    }
  }, [warehouse])

  return (
    <div className={styles.container}>
      {!isLoaded && warehouse && (
        <>
          {isMobile && (
            <MobileHeader>
              {isEdit ? <SaveIcon onClick={handleSubmit(onSubmit)} /> : <PenIcon onClick={handleEdit} />}
            </MobileHeader>
          )}
          <Breadcrumbs customText={warehouse?.name} />
          <StyledHeader>
            <Typography variant="h1">{warehouse?.name}</Typography>
            {!isMobile && (
              <>
                {isEdit ? (
                  <Button onClick={handleSubmit(onSubmit)} color="primary" startIcon={<SaveIcon />}>
                    Сохранить
                  </Button>
                ) : (
                  <IconButton color="inherit" onClick={handleEdit}>
                    <PenIcon />
                  </IconButton>
                )}
              </>
            )}
          </StyledHeader>
          <div className={styles.wrapper}>
            <div className={styles.formItem}>
              <Typography variant="h2" color="text.primary">
                Доступы
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={10}>
                  <Search
                    inputClassName={styles.searchField}
                    control={control}
                    name="users"
                    options={optionsUsers}
                    compareKey="id"
                    placeholder="Введите имя пользователя"
                    emptyMessage="Пользователи отстутствуют"
                    renderOption={(user) => (
                      <div className={styles.option}>
                        <span className={styles.optionInfo}>
                          {user?.lastname} {user?.firstname} {user?.company ? `(${user.company})` : ''}
                        </span>
                        <span className={styles.optionSubInfo}>{rolesRus[user?.role]}</span>
                      </div>
                    )}
                    renderSelected={(user, props) => (
                      <OptionInfo
                        {...props}
                        text={`${user?.lastname || ''} ${user?.firstname} ${user?.company ? `(${user?.company})` : ''}`.trim()}
                        subText={rolesRus[user?.role]}
                      />
                    )}
                    disabled={!isEdit}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
