import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Sidebar } from '../Sidebar/Sidebar'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { Content, style } from './DefaultLayout.style'
import { Outlet } from 'react-router-dom'
import { rolesRus } from '../../types/userTypes'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { useActions } from '../../hooks/useActions'

export const DefaultLayout: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { setIsVisible, setLastScrollY } = useActions()
  const { isMenuOpen, lastScrollY } = useTypeSelector((state) => state.layout)
  const { user, isAuth } = useTypeSelector((state) => state.user)

  const handleScroll = () => {
    const currentScrollY = window.scrollY

    if (currentScrollY > lastScrollY && currentScrollY > 200) {
      setIsVisible(false)
    }

    if (currentScrollY < lastScrollY) {
      setIsVisible(true)
    }

    setLastScrollY(currentScrollY)
  }

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY, isMobile])

  return (
    <Grid sx={style.container} container flexWrap="nowrap">
      {isAuth && <Sidebar />}
      <Content isMenuOpen={isMenuOpen}>
        {isAuth && !isMobile && (
          <Box sx={style.userInfo}>
            <Typography variant="h5" sx={style.username}>
              {user?.lastname} {user.firstname}
            </Typography>
            <Typography sx={style.userRole} component="span">
              {rolesRus[user.role]}
            </Typography>
          </Box>
        )}
        <Outlet />
      </Content>
    </Grid>
  )
}
