import React from 'react'
import { useActions } from '../../hooks/useActions'
import { LogoutIcon } from '../../infrasturcture/icons'
import styles from './Sidebar.module.css'
import { StyledTooltip } from '../../infrasturcture/theme/styled'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'

interface LogoutButtonProps {
  isOpen: boolean
}

export const LogoutButton: React.FC<LogoutButtonProps> = ({ isOpen }) => {
  const { isMobile } = useMediaQueryContext()
  const { logoutThunkCreator, setIsMenuOpen } = useActions()

  const handleClick = () => {
    isMobile && setIsMenuOpen(false)
    logoutThunkCreator()
  }

  return (
    <StyledTooltip
      title={!isOpen ? 'Выйти' : ''}
      placement="right"
      arrow
      disableHoverListener={isMobile}
      disableTouchListener={isMobile}
      disableFocusListener={isMobile}
    >
      <li className={styles.item} onClick={handleClick}>
        <div className={styles.link}>
          <LogoutIcon className={styles.linkIcon} />
          <span className={styles.linkText}>Выйти</span>
        </div>
      </li>
    </StyledTooltip>
  )
}
