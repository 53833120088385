/* eslint-disable no-unused-vars */

export interface DefaultLayoutState {
  isMenuOpen: boolean
  isVisible: boolean
  lastScrollY: number
}

export enum DefaultLayoutActionTypes {
  SET_IS_MENU_OPEN = 'SET_IS_MENU_OPEN',
  SET_IS_VISIBLE = 'SET_IS_VISIBLE',
  SET_LAST_SCROLL_Y = 'SET_LAST_SCROLL_Y'
}

export interface SetIsMenuOpenAction {
  type: DefaultLayoutActionTypes.SET_IS_MENU_OPEN
  payload: boolean
}

export interface SetIsVisibleAction {
  type: DefaultLayoutActionTypes.SET_IS_VISIBLE
  payload: boolean
}

export interface SetLastScrollYAction {
  type: DefaultLayoutActionTypes.SET_LAST_SCROLL_Y
  payload: number
}

export type DefaultLayoutAction = SetIsMenuOpenAction | SetIsVisibleAction | SetLastScrollYAction
