import { DefaultLayoutActionTypes, SetIsMenuOpenAction, SetIsVisibleAction, SetLastScrollYAction } from '../../types/layoutTypes'

export const setIsMenuOpen = (payload: boolean): SetIsMenuOpenAction => ({
  type: DefaultLayoutActionTypes.SET_IS_MENU_OPEN,
  payload
})

export const setIsVisible = (payload: boolean): SetIsVisibleAction => ({
  type: DefaultLayoutActionTypes.SET_IS_VISIBLE,
  payload
})

export const setLastScrollY = (payload: number): SetLastScrollYAction => ({
  type: DefaultLayoutActionTypes.SET_LAST_SCROLL_Y,
  payload
})
