import React from 'react'
import { Button, Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { PlusIcon } from '../../infrasturcture/icons'
import { BookingsContent } from './ui/BookingsContent'
import styles from '../../styles/Operations.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'
import { useNavigate } from 'react-router-dom'

export const Bookings: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileHeader>
          <PlusIcon onClick={() => navigate('/bookings/create')} />
        </MobileHeader>
      )}
      <Breadcrumbs />
      <StyledHeader sx={{ mb: { xs: '32px', md: '40px' } }}>
        <Typography variant="h1">Бронирования</Typography>
        {!isMobile && (
          <Button href="/bookings/create" color="primary" startIcon={<PlusIcon />}>
            Добавить бронирование
          </Button>
        )}
      </StyledHeader>
      <BookingsContent />
    </div>
  )
}
