import { formatISO } from 'date-fns'
import { instance } from '../infrasturcture/http'
import { BalanceFilters, SupplierFilters } from '../types/balancesTypes'
import { getQueryString } from '../infrasturcture/utils/getQueryString'

export default class BalancesApi {
  static async getBalances({ date, manufacturers, search, notNull, mainWarehouses }: BalanceFilters) {
    const response = await instance.get('balances', {
      params: {
        date: formatISO(date ?? new Date()),
        manufacturers: getQueryString(manufacturers, 'id') || null,
        search: search || null,
        notNull: notNull || null,
        mainWarehouses: mainWarehouses || null
      }
    })
    return response.data.data
  }

  static async getSupplierBalances({ manufacturers, search, notNull }: SupplierFilters) {
    const response = await instance.get('supplier', {
      params: {
        manufacturers: getQueryString(manufacturers, 'id') || null,
        search: search || null,
        notNull: notNull || null
      }
    })
    return response.data.data
  }
}
