import { BookingsAction, BookingsActionTypes, BookingsState } from '../../types/bookingsTypes'

const initialState: BookingsState = {
  bookings: [],
  bookingsDone: [],
  selectedBookingsDone: [],
  booking: null,
  products: [],
  productsInBooking: [],
  originalProducts: [],
  limit: 50,
  count: 0,
  countQuickFilter: 0,
  isLoaded: false,
  countErrors: null,
  productsError: null,
  errors: {},
  filters: {
    status: null,
    managers: [],
    products: [],
    warehouses: [],
    startDate: null,
    endDate: null,
    sku: null,
    quickFilter: false
  }
}

export const bookingsReducer = (state = initialState, action: BookingsAction): BookingsState => {
  switch (action.type) {
    case BookingsActionTypes.GET_BOOKINGS:
      return {
        ...state,
        isLoaded: false,
        count: action.payload.count,
        countQuickFilter: action.payload.countQuickFilter,
        bookings: [...state.bookings, ...action.payload.rows]
      }
    case BookingsActionTypes.GET_BOOKING:
      return {
        ...state,
        isLoaded: false,
        booking: action.payload
      }
    case BookingsActionTypes.GET_BOOKINGS_DONE:
      return {
        ...state,
        isLoaded: false,
        bookingsDone: action.payload
      }
    case BookingsActionTypes.SET_BOOKING:
      return {
        ...state,
        booking: action.payload
      }
    case BookingsActionTypes.SET_BOOKINGS_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }
    case BookingsActionTypes.GET_BOOKINGS_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case BookingsActionTypes.ADD_BOOKING_PRODUCT:
      return {
        ...state,
        productsInBooking: [...state.productsInBooking, action.payload]
      }
    case BookingsActionTypes.REMOVE_BOOKING_PRODUCT:
      return {
        ...state,
        productsInBooking: state.productsInBooking.filter((p) => p.id !== action.payload)
      }
    case BookingsActionTypes.PLUS_BOOKING_PRODUCT:
      return {
        ...state,
        productsInBooking: state.productsInBooking
          .map((item) =>
            item.id === action.payload ? { ...item, count: item.count + 1, write_off_left: item.write_off_left + 1 } : item
          )
          .filter((item) => item.count > 0)
      }
    case BookingsActionTypes.MINUS_BOOKING_PRODUCT:
      return {
        ...state,
        productsInBooking: state.productsInBooking
          .map((item) =>
            item.id === action.payload ? { ...item, count: item.count - 1, write_off_left: item.write_off_left - 1 } : item
          )
          .filter((item) => item.count > 0)
      }
    case BookingsActionTypes.SET_BOOKINGS_DONE:
      return {
        ...state,
        bookingsDone: action.payload
      }
    case BookingsActionTypes.SELECT_BOOKINGS_DONE:
      return {
        ...state
        /*  bookingsDone: state.bookingsDone.map((item) =>
          item.id === action.payload ? { ...item, selected: !item.selected } : item
        ) */
      }
    case BookingsActionTypes.SET_BOOKINGS_DONE_PRODUCTS:
      return {
        ...state,
        selectedBookingsDone: action.payload
      }
    case BookingsActionTypes.PLUS_BOOKINGS_DONE_PRODUCT:
      return {
        ...state,
        selectedBookingsDone: state.selectedBookingsDone
          .map((item) =>
            item.id === action.payload.productId &&
            item.order_id === action.payload.bookingId &&
            item.isBooking === action.payload.isBooking
              ? { ...item, count: item.count + 1, write_off_left: item.write_off_left - 1 }
              : item
          )
          .filter((item) => item.count > 0)
      }
    case BookingsActionTypes.MINUS_BOOKINGS_DONE_PRODUCT:
      return {
        ...state,
        selectedBookingsDone: state.selectedBookingsDone
          .map((item) =>
            item.id === action.payload.productId &&
            item.order_id === action.payload.bookingId &&
            item.isBooking === action.payload.isBooking
              ? { ...item, count: item.count - 1, write_off_left: item.write_off_left + 1 }
              : item
          )
          .filter((item) => item.count > 0)
      }
    case BookingsActionTypes.SET_BOOKINGS_COUNT_ERRORS:
      return {
        ...state,
        countErrors: action.payload
      }

    case BookingsActionTypes.SET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload
      }
    case BookingsActionTypes.SET_BOOKING_PRODUCTS:
      return {
        ...state,
        productsInBooking: action.payload,
        originalProducts: action.payload
      }
    case BookingsActionTypes.SET_BOOKING_SEARCH_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case BookingsActionTypes.SET_BOOKING_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      }
    case BookingsActionTypes.SET_BOOKINGS_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}
