import React from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, Typography } from '@mui/material'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { BookingDoneProduct } from '../../types/bookingsTypes'
import { useActions } from '../../hooks/useActions'
import { useNavigate } from 'react-router-dom'
import groupBy from 'lodash.groupby'
import { OperationProduct } from '../../types/operationsTypes'
import sumBy from 'lodash.sumby'
import { BookingsDoneTabs } from './ui/BookingsDoneTabs'
import styles from '../../styles/Operations.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

const customPaths = {
  '/operations': 'Операции',
  '/operations/add/write_off': 'Списание',
  '/operations/add/write_off/bookings_done': 'Бронирования для списания'
}

export const BookingsDone: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { setBookingsErrors, setOperationProducts, setBookingsDoneProducts } = useActions()
  const { bookingsDone, errors, products } = useTypeSelector((state) => state.bookings)
  const { productsInOperation } = useTypeSelector((state) => state.operations)

  const handleAddSelected = () => {
    const productsInBookingsDone = bookingsDone
      .flatMap((b) => b.productsInBooking.concat(b.productsInOrder))
      .reduce<BookingDoneProduct[]>(
        (arr, p) => (p.selected ? [...arr, { ...p, count: p.write_off_left, write_off_left: 0 }] : arr),
        []
      )

    const groupedProducts = groupBy([...productsInOperation, ...productsInBookingsDone], 'id')

    const unionProducts = Object.entries(groupedProducts).reduce<OperationProduct[]>((arr, [id, group]) => {
      const totalCount = sumBy(group, 'count')
      const bookingDoneCount = productsInBookingsDone.reduce((acc, item) => (item.id === id ? acc + item.count : acc + 0), 0)
      const product = products.find((p) => p.id === id)
      /*       console.log(product?.name, count, operationCount, count ? count - operationCount : 1) */
      return product ? [...arr, { ...product, count: bookingDoneCount || totalCount }] : arr
    }, [])

    setOperationProducts(unionProducts)
    setBookingsDoneProducts(productsInBookingsDone)
    navigate(-1)
  }

  return (
    <div className={styles.container}>
      {isMobile && <MobileHeader />}
      <Breadcrumbs customPaths={customPaths} />
      <StyledHeader sx={{ flexWrap: 'wrap', gap: { xs: '12px' }, mb: { xs: '20px', md: '40px' } }}>
        <Typography variant="h1">Бронирования для списания</Typography>
        {!!bookingsDone.length && (
          <Button onClick={handleAddSelected} color="primary">
            Добавить
          </Button>
        )}
      </StyledHeader>
      <BookingsDoneTabs />
      <SnacbarModal
        isError
        isActions={false}
        onCancel={() => setBookingsErrors({})}
        open={!!errors?.bookingsDone}
        message={errors?.bookingsDone}
      />
    </div>
  )
}
