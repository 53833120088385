import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import BookingsApi from '../../api/BookingsApi'
import {
  AddBookingProduct,
  Booking,
  BookingDone,
  BookingDoneProduct,
  BookingProduct,
  BookingsAction,
  BookingsActionTypes,
  SetBookingSearchProducts,
  MinusBookingProduct,
  MinusBookingsDoneProduct,
  PlusBookingProduct,
  PlusBookingsDoneProduct,
  SelectBookingsDone,
  SetBooking,
  SetBookingFilters,
  SetBookingProducts,
  SetBookingsCountErrors,
  SetBookingsDone,
  SetBookingsDoneProducts,
  SetBookingsErrorsAction,
  SetBookingsLoaded,
  SetBookings,
  ProductInBooking,
  RemoveBookingProductAction
} from '../../types/bookingsTypes'
import { SetAuthErrorAction } from '../../types/userTypes'
import { setAuthError } from './userActions'
import { NavigateFunction } from 'react-router-dom'
import { ErrorMessages } from '../../types/errorTypes'
import { errorsHandling } from '../../infrasturcture/utils/errorsHandling'
import { OrderFilters } from '../../types/ordersTypes'

type ThunkType = ThunkAction<void, RootState, unknown, BookingsAction | SetAuthErrorAction>

const setBookingsLoaded = (payload: boolean): SetBookingsLoaded => ({
  type: BookingsActionTypes.SET_BOOKINGS_LOADED,
  payload
})

export const addBookingProduct = (payload: ProductInBooking): AddBookingProduct => ({
  type: BookingsActionTypes.ADD_BOOKING_PRODUCT,
  payload
})

export const plusBookingProduct = (payload: string): PlusBookingProduct => ({
  type: BookingsActionTypes.PLUS_BOOKING_PRODUCT,
  payload
})

export const minusBookingProduct = (payload: string): MinusBookingProduct => ({
  type: BookingsActionTypes.MINUS_BOOKING_PRODUCT,
  payload
})

export const setBookingsCountErrors = (payload: { id: string; message: string } | null): SetBookingsCountErrors => ({
  type: BookingsActionTypes.SET_BOOKINGS_COUNT_ERRORS,
  payload
})

export const setBookingProducts = (payload: ProductInBooking[]): SetBookingProducts => ({
  type: BookingsActionTypes.SET_BOOKING_PRODUCTS,
  payload
})

export const setBookingSearchProducts = (payload: BookingProduct[]): SetBookingSearchProducts => ({
  type: BookingsActionTypes.SET_BOOKING_SEARCH_PRODUCTS,
  payload
})

export const setBookings = (payload: Booking[]): SetBookings => ({
  type: BookingsActionTypes.SET_BOOKINGS,
  payload
})

export const setBooking = (payload: Booking | null): SetBooking => ({
  type: BookingsActionTypes.SET_BOOKING,
  payload
})

export const selectBookingsDone = (payload: string): SelectBookingsDone => ({
  type: BookingsActionTypes.SELECT_BOOKINGS_DONE,
  payload
})

export const setBookingsDone = (payload: BookingDone[]): SetBookingsDone => ({
  type: BookingsActionTypes.SET_BOOKINGS_DONE,
  payload
})

export const setBookingsDoneProducts = (payload: BookingDoneProduct[]): SetBookingsDoneProducts => ({
  type: BookingsActionTypes.SET_BOOKINGS_DONE_PRODUCTS,
  payload
})

export const plusBookingsDoneProduct = (bookingId: string, productId: string, isBooking: boolean): PlusBookingsDoneProduct => ({
  type: BookingsActionTypes.PLUS_BOOKINGS_DONE_PRODUCT,
  payload: { bookingId, productId, isBooking }
})

export const minusBookingsDoneProduct = (bookingId: string, productId: string, isBooking: boolean): MinusBookingsDoneProduct => ({
  type: BookingsActionTypes.MINUS_BOOKINGS_DONE_PRODUCT,
  payload: { bookingId, productId, isBooking }
})

export const setBookingFilters = (payload: Partial<OrderFilters>): SetBookingFilters => ({
  type: BookingsActionTypes.SET_BOOKING_FILTERS,
  payload
})

export const removeBookingProduct = (payload: string): RemoveBookingProductAction => ({
  type: BookingsActionTypes.REMOVE_BOOKING_PRODUCT,
  payload
})

export const setBookingsErrors = (payload: ErrorMessages): SetBookingsErrorsAction => ({
  type: BookingsActionTypes.SET_BOOKINGS_ERRORS,
  payload
})

export const getBookingsThunkCreator = (limit = 50, page = 1, filters: OrderFilters): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setBookingsLoaded(true))
      const data = await BookingsApi.getBookings(limit, page, filters)
      dispatch({ type: BookingsActionTypes.GET_BOOKINGS, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getBookingThunkCreator = (id: string, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setBookingsLoaded(true))
      const data = await BookingsApi.getBooking(id)
      dispatch({ type: BookingsActionTypes.GET_BOOKING, payload: data })
    } catch (e: any) {
      if ([403, 404, 503].includes(e.response.status)) {
        return navigate(`/${e.response.status}`)
      }
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getBookingsProductsInWarehouseThunkCreator = (id: string): ThunkType => {
  return async (dispatch) => {
    try {
      const data = await BookingsApi.getProducts(id)
      dispatch({ type: BookingsActionTypes.GET_BOOKINGS_PRODUCTS, payload: data || [] })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getBookingsDoneThunkCreator = (warehouseId: string): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setBookingsLoaded(true))
      const data = await BookingsApi.getBookingDone(warehouseId)
      dispatch({ type: BookingsActionTypes.GET_BOOKINGS_DONE, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const createBookingThunkCreator = (booking: any, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await BookingsApi.createBooking(booking)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setBookingsErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const editBookingThunkCreator = (booking: Booking, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await BookingsApi.editBooking(booking)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setBookingsErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}
