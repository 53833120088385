/* eslint-disable no-unused-vars */
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from '@mui/material'
import { InputSearch } from '../../../components/Input/InputSearch'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { MuiTabs } from '../../../components/MuiComponents/MuiTabs/MuiTabs'
import { useBalance } from '../../../hooks/useBalance'
import clsx from 'clsx'
import { BalancesFilter } from '../../../components/Balances/BalancesFilter'
import { Loader } from '../../../components/Loader/Loader'
import { EmptyMessage } from '../../../components/EmptyMessage/EmptyMessage'
import styles from '../../../styles/Balances.module.css'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

export const BalancesContent: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { setBalances, getBalancesThunkCreator, getManufacturersThunkCreator, setBalanceFilters } = useActions()
  const { balances, isLoaded, filters } = useTypeSelector((state) => state.balances)
  const { manufacturers } = useTypeSelector((state) => state.manufacturers)
  const {
    sortedWarehouses,
    dragStartHandler,
    dragLeaveHandler,
    dragEndHandler,
    dragOverHandler,
    dragDropHandler,
    CELL_WIDTH_STYLE,
    contentScrollRef,
    fixedScrollRef,
    headerScrollRef,
    syncScroll
  } = useBalance()

  const [query, setQuery] = useState<string>('')
  const [tableWidth, setTableWidth] = useState<number>(0)
  const [direction, setDirection] = useState<'warehouses' | 'products'>('warehouses')
  const tableRef = useRef<HTMLTableElement>(null)

  const debouncedSearch = useCallback(
    debounce((search: string) => setBalanceFilters({ search }), 800),
    []
  )

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setQuery(value)
    debouncedSearch(value)
  }

  /*   const tabs = [
    { label: 'По складам', onClick: () => setDirection('warehouses') },
    { label: 'По товарам', onClick: () => setDirection('products') }
  ] */

  useEffect(() => {
    if (tableRef.current) setTableWidth(tableRef.current.offsetWidth)
  }, [balances, isMobile])

  useEffect(() => {
    setBalances([])
    !manufacturers.length && getManufacturersThunkCreator()
  }, [])

  useEffect(() => {
    getBalancesThunkCreator(filters)
  }, [filters])

  return (
    <div className={styles.content}>
      <InputSearch onChange={handleSearch} value={query} placeholder="Введите название или код товара" />
      <BalancesFilter type="balances" />
      <div className={clsx(styles.tableContainer)}>
        {!!sortedWarehouses.length && !!balances.length ? (
          <>
            <div className={styles.tableHeaderContainer} ref={headerScrollRef} onScroll={syncScroll}>
              <table className={styles.tableHeader}>
                <thead className={styles.thead}>
                  {direction === 'warehouses' ? (
                    <tr className={styles.row}>
                      <td className={clsx(styles.cell, styles.productCell, styles.theadFirstCellSticky)}>Товар</td>
                      <td
                        className={clsx(styles.cell, styles.balanceCell, styles.theadSecondCellSticky)}
                        style={CELL_WIDTH_STYLE}
                      >
                        Остаток
                      </td>
                      {sortedWarehouses.map((item) => (
                        <td
                          key={item.id}
                          className={clsx(styles.cell, styles.cursorMove, styles.warehouseCell)}
                          style={CELL_WIDTH_STYLE}
                          draggable={true}
                          onDragStart={(e) => dragStartHandler(e, item)}
                          onDragLeave={(e) => dragLeaveHandler(e)}
                          onDragEnd={(e) => dragEndHandler(e, item)}
                          onDragOver={(e) => dragOverHandler(e)}
                          onDrop={(e) => dragDropHandler(e, item)}
                        >
                          {item.name}
                        </td>
                      ))}
                    </tr>
                  ) : (
                    <tr className={styles.row}>
                      <td className={clsx(styles.cell, styles.productCell, styles.theadFirstCellSticky)}>Склад</td>
                      {balances.map((item) => (
                        <td key={item.id} className={clsx(styles.cell, styles.warehouseCell)} style={CELL_WIDTH_STYLE}>
                          {item.name}
                        </td>
                      ))}
                    </tr>
                  )}
                </thead>
              </table>
            </div>
            <div className={styles.tableBodyContainer} ref={contentScrollRef} onScroll={syncScroll}>
              <table className={styles.tableBody} ref={tableRef}>
                <tbody className={styles.emptyBody}></tbody>
                <tbody className={styles.tbody}>
                  {direction === 'warehouses'
                    ? balances.map((item) => (
                        <tr key={item.id} className={styles.row}>
                          <td className={clsx(styles.cell, styles.productCell, styles.tbodyFirstCellSticky)}>
                            <ProductInfo product={item} clamp={false} />
                          </td>
                          <td
                            className={clsx(styles.cell, styles.balanceCell, styles.tbodySecondCellSticky)}
                            style={CELL_WIDTH_STYLE}
                          >
                            {item.totalCount}
                          </td>
                          {sortedWarehouses.map(({ id }) => (
                            <td key={id} className={clsx(styles.cell, styles.warehouseCell)} style={CELL_WIDTH_STYLE}>
                              {item.warehouses.find((warehouse) => warehouse.warehouse_id === id)?.count || 0}
                            </td>
                          ))}
                        </tr>
                      ))
                    : sortedWarehouses.map((warehouse) => (
                        <tr key={warehouse.id} className={styles.row}>
                          <td className={clsx(styles.cell, styles.productCell, styles.tbodyFirstCellSticky)}>{warehouse.name}</td>
                          {balances.map((balance) => (
                            <td key={balance.id} className={clsx(styles.cell, styles.warehouseCell)} style={CELL_WIDTH_STYLE}>
                              {balance.warehouses.find((w) => w.warehouse_id === warehouse.id)?.count || 0}
                            </td>
                          ))}
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </>
        ) : isLoaded ? (
          <Loader className={styles.loader} />
        ) : (
          <EmptyMessage className={styles.emptyData}>Данные об остатках отсутствуют</EmptyMessage>
        )}
      </div>
      {!!sortedWarehouses.length && !!balances.length && (
        <div className={styles.fixedScrollbarX} ref={fixedScrollRef} onScroll={syncScroll}>
          <div className={styles.scrollbarInnerX} style={{ width: tableWidth }}></div>
        </div>
      )}
    </div>
  )
}
