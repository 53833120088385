import React from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { Form } from './ui/Form'
import { useNavigate } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { warehousesSchema } from '../../infrasturcture/yupSchemes/warehousesSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { SaveIcon } from '../../infrasturcture/icons'
import styles from './Warehouses.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

export const CreateWarehouse: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { createWarehouseThunkCreator } = useActions()
  const methods = useForm({ resolver: yupResolver(warehousesSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    return createWarehouseThunkCreator(data, () => methods.reset(), navigate)
  }

  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileHeader>
          <SaveIcon onClick={methods.handleSubmit(onSubmit)} />
        </MobileHeader>
      )}
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Новый склад</Typography>
        {!isMobile && (
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            disabled={methods.formState.isSubmitting}
            color="primary"
            startIcon={<SaveIcon />}
          >
            Сохранить
          </Button>
        )}
      </StyledHeader>
      <FormProvider {...methods}>
        <Form type="create" onSubmit={onSubmit} />
      </FormProvider>
    </div>
  )
}
