import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, IconButton, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { DeleteIcon, PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { FormProvider, useForm } from 'react-hook-form'
import { Form } from './ui/Form'
import { yupResolver } from '@hookform/resolvers/yup'
import { sectionSchema } from '../../infrasturcture/yupSchemes/sectionSchema'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import styles from './Sections.module.css'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'

export const EditSection: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const { getSectionThunkCreator, editSectionThunkCreator, setSection, deleteSectionThunkCreator, setSectionsErrors } =
    useActions()
  const { section, isLoaded, sections, errors } = useTypeSelector((state) => state.sections)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isWarning, setIsWarning] = useState<boolean>(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const methods = useForm({ resolver: yupResolver(sectionSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    const { users, ...rest } = data
    return editSectionThunkCreator({ id, ...rest }, () => setIsEdit(false), navigate)
  }

  const deleteSection = () => {
    deleteSectionThunkCreator(id!, () => navigate('/products/sections'), navigate)
    setIsWarning(false)
  }

  const handleEdit = () => setIsEdit(true)
  const handleWarning = () => setIsWarning(true)

  const handleFetch = async (id: string) => {
    const findSection = sections?.find((s) => s.id === id)
    findSection ? setSection(findSection) : getSectionThunkCreator(id, navigate)
  }

  useEffect(() => {
    handleFetch(id!)
  }, [])

  useEffect(() => {
    section && methods.reset(section)
  }, [section])

  return (
    <div className={styles.container}>
      {!isLoaded && section && (
        <>
          {isMobile && (
            <MobileHeader>
              {isEdit ? (
                <>
                  <SaveIcon onClick={methods.handleSubmit(onSubmit)} />
                  <DeleteIcon onClick={handleWarning} />
                </>
              ) : (
                <PenIcon onClick={handleEdit} />
              )}
            </MobileHeader>
          )}
          <Breadcrumbs customText={section?.name} />
          <StyledHeader>
            <Typography variant="h1">{section?.name}</Typography>
            {!isMobile && (
              <>
                {isEdit ? (
                  <>
                    <Button
                      onClick={methods.handleSubmit(onSubmit)}
                      disabled={methods.formState.isSubmitting}
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      Сохранить
                    </Button>
                    <Button onClick={handleWarning} ref={buttonRef} color="error" startIcon={<DeleteIcon />}>
                      Удалить
                    </Button>
                  </>
                ) : (
                  <IconButton color="inherit" onClick={handleEdit}>
                    <PenIcon />
                  </IconButton>
                )}
              </>
            )}
          </StyledHeader>
          <FormProvider {...methods}>
            <Form type="edit" disabled={!isEdit} onSubmit={onSubmit} />
          </FormProvider>
        </>
      )}
      <SnacbarModal
        onAccept={deleteSection}
        onCancel={() => setIsWarning(false)}
        open={isWarning}
        message={`Вы действительно хотите удалить раздел '${section?.name}'`}
      />
      <SnacbarModal isError isActions={false} onCancel={() => setSectionsErrors({})} open={!!errors?.id} message={errors?.id} />
    </div>
  )
}
