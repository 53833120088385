import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Warehouse } from '../types/warehousesTypes'
import { useActions } from './useActions'

import { useForm } from 'react-hook-form'
import { useTypeSelector } from './useTypeSelector'

export const useBalance = () => {
  const { setBalancesWarehouses, updateWarehouseOrderThunkCreator } = useActions()
  const { warehouses } = useTypeSelector((state) => state.balances)

  const [currentWarehouse, setCurrentWarehouse] = useState<Warehouse | null>(null)

  const lastElement = useRef<HTMLDivElement | null>(null)
  const CELL_WIDTH_STYLE: React.CSSProperties = warehouses.length <= 3 ? { maxWidth: 135, minWidth: 135 } : {}
  const isFiltered = useRef<boolean>(false)

  const fixedScrollRef = useRef<HTMLDivElement>(null)
  const headerScrollRef = useRef<HTMLDivElement>(null)
  const contentScrollRef = useRef<HTMLDivElement>(null)

  const syncScroll = useCallback((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement
    const scrollLeft = target.scrollLeft

    if (fixedScrollRef.current) fixedScrollRef.current.scrollLeft = scrollLeft

    if (contentScrollRef.current) contentScrollRef.current.scrollLeft = scrollLeft

    if (headerScrollRef.current) headerScrollRef.current.scrollLeft = scrollLeft
  }, [])

  const sortedWarehouses = useMemo(
    () => warehouses.sort((a, b) => (a.warehouse_order > b.warehouse_order ? 1 : -1)),
    [warehouses]
  )

  const methods = useForm({ defaultValues: { date: new Date() } })

  const dragStartHandler = (e: any, th: Warehouse) => {
    setCurrentWarehouse(th)
  }

  const dragLeaveHandler = (e: any) => {
    e.target.style.boxShadow = 'none'
  }

  const dragEndHandler = (e: any, th: Warehouse) => {
    e.target.style.boxShadow = 'none'
    updateWarehouseOrderThunkCreator({
      id: th.id,
      old: currentWarehouse!.warehouse_order,
      new: th.warehouse_order
    })
  }

  const dragOverHandler = (e: any) => {
    e.preventDefault()
    e.target.style.boxShadow = '3px 0px 3px rgba(0, 0, 0, 0.3)'
  }

  const dragDropHandler = (e: any, th: Warehouse) => {
    e.preventDefault()

    const newWarehouses =
      currentWarehouse!.warehouse_order > th.warehouse_order
        ? warehouses.map((item) => {
            if (item.id === currentWarehouse?.id) {
              return { ...item, warehouse_order: th.warehouse_order }
            }
            if (
              item.id !== currentWarehouse?.id &&
              item.warehouse_order < currentWarehouse!.warehouse_order &&
              item.warehouse_order >= th.warehouse_order
            ) {
              return { ...item, warehouse_order: item.warehouse_order + 1 }
            }
            return item
          })
        : warehouses.map((item) => {
            if (item.id === currentWarehouse?.id) {
              return { ...item, warehouse_order: th.warehouse_order }
            }
            if (
              item.id !== currentWarehouse?.id &&
              item.warehouse_order > currentWarehouse!.warehouse_order &&
              item.warehouse_order <= th.warehouse_order
            ) {
              return { ...item, warehouse_order: item.warehouse_order - 1 }
            }
            return item
          })

    setBalancesWarehouses(newWarehouses)

    e.target.style.boxShadow = 'none'
  }

  const value = useMemo(
    () => ({
      dragStartHandler,
      dragLeaveHandler,
      dragEndHandler,
      dragOverHandler,
      dragDropHandler,
      syncScroll,
      CELL_WIDTH_STYLE,
      lastElement,
      methods,
      isFiltered,
      sortedWarehouses,
      fixedScrollRef,
      headerScrollRef,
      contentScrollRef
    }),
    [CELL_WIDTH_STYLE, lastElement, fixedScrollRef, headerScrollRef, contentScrollRef, methods, isFiltered, sortedWarehouses]
  )

  return value
}
