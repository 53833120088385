/* eslint-disable react/react-in-jsx-scope */
import { Box, FormControl, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'
import { Link } from 'react-router-dom'

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '&:focus-within': {
    color: theme.palette.primary.main
  },
  '&:not(:focus-within)': {
    color: '#4B4B4B'
  }
}))

export const StyledBoxIcon = styled(Box)<{ error: boolean }>(({ theme, error }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  left: 30,
  top: '50%',
  transform: 'translateY(-50%)',
  fill: error ? theme.palette.error.main : 'currentcolor'
}))

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 10,
    backgroundColor: '#4B4B4B',
    fontSize: '15px',
    fontWeight: 500
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#4B4B4B'
  }
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
  fill: 'currentcolor',
  gap: '16px'
}))

export const StyledInputContainer = styled(Box)({
  width: 205,
  height: 32,
  borderRadius: 8,
  fontFamily: 'Roboto, sans-serif',
  fontSize: 12,
  fontWeight: 400,
  marginRight: 12,
  color: '#4b4b4b',
  border: ' 1px solid #ababab52',
  '@media screen and (max-width: 1000px)': {
    width: '100%'
  }
})

export const StyledInput = styled('input')({
  width: 'inherit',
  height: 'inherit',
  font: 'inherit',
  border: 'none',
  appearance: 'none',
  outline: 'none',
  borderRadius: 8,
  padding: '0 16px',
  background: 'none',
  '&::placeholder': {
    fontSize: 12,
    color: '#ababab99',
    fontWeight: 400
  }
})

export const StyledSelectedContainer = styled(Box)({
  display: 'flex',
  marginTop: 20,
  flexWrap: 'wrap',
  gap: 12
})

export const StyledCell = styled('span', { shouldForwardProp: (prop) => prop !== 'variant' })<{ variant?: 'default' | 'green' }>(
  ({ theme, variant = 'default' }) => ({
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '21px',
    color: variant === 'green' ? theme.palette.primary.main : theme.palette.secondary.main,
    '@media screen and (max-width: 1000px)': {
      color: theme.palette.text.primary
    }
  })
)

const linkStyle = {
  fontWeight: 500,
  fontFamily: 'Roboto, sans-serif',
  fontSize: 15,
  lineHeight: '21px',
  color: '#0060b9',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}

export const StyledLink = styled('a')(linkStyle)

export const StyledLinkRouter = styled(Link)(linkStyle)
