import { format, parseISO } from 'date-fns'

const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']

export const formatDate = (date: string | Date | null, formatString = 'MMMM d, yyyy'): string => {
  if (date) {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    const formattedDate = format(parsedDate, formatString)
    return formattedDate.replace(format(parsedDate, 'MMMM'), months[parsedDate.getMonth()])
  }
  return ''
}
