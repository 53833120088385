import React from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, Typography } from '@mui/material'
import { OrderPostingProduct } from '../../types/ordersTypes'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { useNavigate, useParams } from 'react-router-dom'
import groupBy from 'lodash.groupby'
import sumBy from 'lodash.sumby'
import { OperationProduct, operationTypes } from '../../types/operationsTypes'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { OrdersPostingTabs } from './ui/OrdersPostingTabs'
import styles from '../../styles/Operations.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

const customPaths = {
  '/operations': 'Операции',
  '/operations/add/posting': 'Оприходование',
  '/operations/add/posting/orders_posting': 'Заявки для оприходования',
  '/operations/add/moving': 'Перемещение',
  '/operations/add/moving/orders_posting': 'Заявки для оприходования'
}

export const OrdersPosting: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { type } = useParams()
  const TYPE = type?.toUpperCase()
  const { setOrdersPostingProducts, setOperationProducts, setOrdersErrors } = useActions()
  const { productsInOperation, products: stateProducts } = useTypeSelector((state) => state.operations)
  const { ordersPosting, selectedOrdersPosting, errors } = useTypeSelector((state) => state.orders)

  const handleAddSelected = () => {
    const availableProductsCount: Record<string, number> =
      TYPE === operationTypes.MOVING ? stateProducts.reduce((obj, item) => ({ ...obj, [item.id]: item.count }), {}) : {}

    const productsInOrdersPosting = ordersPosting
      .flatMap((o) => o.productsInOrder)
      .reduce<OrderPostingProduct[]>((arr, p) => {
        if (p.selected) {
          if (TYPE === operationTypes.MOVING) {
            const availableCount = availableProductsCount[p.id]
            const count = Math.min(p.count_left, availableCount)
            availableProductsCount[p.id] -= count
            return [...arr, { ...p, count, count_left: p.count_left - count }]
          }
          return [...arr, { ...p, count: p.count_left, count_left: 0 }]
        }
        return arr
      }, [])

    const groupedProducts = groupBy([...productsInOperation, ...productsInOrdersPosting], 'id')

    const unionProducts = Object.entries(groupedProducts).reduce<OperationProduct[]>((arr, [id, group]) => {
      const totalCount = sumBy(group, 'count')
      const postingCount = productsInOrdersPosting.reduce((acc, item) => (item.id === id ? acc + item.count : acc), 0)
      const product = stateProducts.find((p) => p.id === id)

      if (TYPE === operationTypes.MOVING) {
        return product ? [...arr, { ...product, count: postingCount || totalCount }] : arr
      }

      const prevPostingCount = selectedOrdersPosting.reduce((acc, item) => (item.id === id ? acc + item.count : acc), 0)
      const count = prevPostingCount === 0 && postingCount > 0 ? totalCount - 1 : totalCount

      return product ? [...arr, { ...product, count: count - prevPostingCount }] : arr
    }, [])

    setOperationProducts(unionProducts)
    setOrdersPostingProducts(productsInOrdersPosting)
    navigate(-1)
  }

  return (
    <div className={styles.container}>
      {isMobile && <MobileHeader />}
      <Breadcrumbs customPaths={customPaths} />
      <StyledHeader sx={{ flexWrap: 'wrap', gap: { xs: '12px' }, mb: { xs: '20px', md: '40px' } }}>
        <Typography variant="h1">Заявки для оприходования</Typography>
        {!!ordersPosting.length && (
          <Button onClick={handleAddSelected} color="primary">
            Добавить
          </Button>
        )}
      </StyledHeader>
      <OrdersPostingTabs />
      <SnacbarModal
        isError
        isActions={false}
        onCancel={() => setOrdersErrors({})}
        open={!!errors?.ordersPosting}
        message={errors?.ordersPosting}
      />
    </div>
  )
}
