import React, { useState } from 'react'
import { StyledCell, StyledLinkRouter } from '../../../infrasturcture/theme/styled'
import { Box } from '@mui/material'
import { OrderPosting, OrderPostingProduct } from '../../../types/ordersTypes'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { Status } from '../../../components/Status/Status'
import { formatDate } from '../../../infrasturcture/utils/formatDate'
import { BitrixLink } from '../../../components/BitrixLink/BitrixLink'
import { OperationComment } from '../../../components/Operations/OperationComment'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { Checkbox } from '../../../components/Checkbox/Checkbox'
import { useDoubleClick } from '../../../hooks/useDoubleClick'
import { OrderPostingRow } from '../ui/OrderPostingRow'
import { useParams, useSearchParams } from 'react-router-dom'
import { Option } from '../../../components/Options/Option'
import { useOrdersPosting } from '../../../hooks/useOrdersPosting'
import { MuiTabs } from '../../../components/MuiComponents/MuiTabs/MuiTabs'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { money } from '../../../types/productsTypes'
import { Count } from '../../../components/Operations/Count'
import { EmptyMessage } from '../../../components/EmptyMessage/EmptyMessage'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

export const OrdersPostingTabs: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { type } = useParams()
  const [searchParams] = useSearchParams()
  const productId = searchParams.get('productId')
  const { ordersPosting } = useTypeSelector((state) => state.orders)
  const { onChange, isAvailableToSelect } = useOrdersPosting(type?.toUpperCase())

  const [selectedId, setSelectedId] = useState<string | null>(null)

  const products = ordersPosting.flatMap((p) => p.productsInOrder).filter((p) => p.id === productId)

  const handleClick = useDoubleClick(
    (id: string) => setSelectedId((prev) => (prev === id ? null : id)),
    () => undefined
  )

  const ordersColumns: VgTableColumnType<OrderPosting>[] = [
    {
      key: 'id',
      title: '',
      width: '5%',
      style: { paddingLeft: 22 },
      render: (_, { id, productsInOrder }) => {
        return (
          <Box sx={{ m: { xs: '-10px -10px 0 0', md: '-10px 0' } }}>
            <Checkbox
              onChange={() => onChange(id, productsInOrder)}
              value={id}
              checked={productsInOrder.every((p) => p.selected)}
              shakeOnClick={!isAvailableToSelect(productsInOrder)}
              error={!isAvailableToSelect(productsInOrder)}
            />
          </Box>
        )
      },
      gridArea: [1, 2, 2, 3],
      justifyItems: 'right',
      alignItems: 'start',
      removeTitle: true
    },
    {
      key: 'sku',
      title: 'Номер',
      width: '10%',
      gridArea: [1, 1, 2, 2]
    },
    {
      key: 'status',
      title: 'Статус',
      render: (_, { status }) => <Status type={status} />,
      width: '15%',
      gridArea: [2, 1, 3, 3]
    },
    {
      key: 'user_firstname',
      title: 'Пользователь',
      width: '15%',
      render: (_, item) => (
        <Option
          text={`${item.user_lastname || ''} ${item.user_firstname}`.trim()}
          subText={item.user_company}
          fontWeight={500}
          variant="table"
        />
      ),
      gridArea: [3, 1, 4, 3]
    },
    {
      key: 'created_at',
      title: 'Дата создания',
      render: (_, item) => <StyledCell>{formatDate(item.created_at)}</StyledCell>,
      width: '13%',
      gridArea: [7, 1, 8, 3]
    },
    {
      key: 'warehouse_name',
      title: 'Склад',
      width: '15%',
      gridArea: [4, 1, 5, 3]
    },
    {
      key: 'bitrix_id',
      title: 'Id B24',
      render: (_, item) => <BitrixLink bitrixId={item.bitrix_id} />,
      width: '10%',
      gridArea: [5, 1, 6, 3]
    },
    {
      key: 'comment',
      title: 'Комментарий',
      render: (_, { comment }) => <OperationComment>{comment}</OperationComment>,
      width: '17%',
      gridArea: [6, 1, 7, 3]
    }
  ]

  const productsColumns: VgTableColumnType<OrderPostingProduct>[] = [
    {
      key: 'id',
      title: '',
      render: (_, product) => {
        return (
          <Box sx={{ m: { xs: '-10px -10px 0 0', md: '-10px 0' } }}>
            <Checkbox
              onChange={() => onChange(product.order_id, product)}
              checked={product.selected}
              shakeOnClick={!isAvailableToSelect(product)}
              error={!isAvailableToSelect(product)}
            />
          </Box>
        )
      },
      width: '5%',
      style: { paddingLeft: 22 },
      gridArea: [1, 2, 2, 3],
      justifyItems: 'right',
      alignItems: 'start',
      removeTitle: true
    },
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} />,
      width: '25%',
      gridArea: [1, 1, 2, 2]
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '10%',
      gridArea: [2, 1, 3, 2]
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '12%',
      gridArea: [2, 2, 3, 3],
      justifyItems: 'right'
    },
    {
      key: 'delivery_time',
      title: 'Время доставки',
      render: (_, item) => `${item.delivery_time} дн`,
      width: '12%',
      textAlign: 'center',
      gridArea: [3, 1, 4, 2]
    },
    {
      key: 'order_sku',
      title: 'Номер заявки',
      render: (_, item) => (
        <StyledLinkRouter target="_blank" to={`/orders/${item.order_sku}`}>
          {item.order_sku}
        </StyledLinkRouter>
      ),
      width: '9%',
      textAlign: 'center',
      gridArea: [3, 2, 4, 3],
      justifyItems: 'right'
    },
    {
      key: 'count_left',
      title: 'Осталось оприходовать',
      render: (_, item) => <Count>{item.count_left}</Count>,
      textAlign: 'center',
      width: '13%',
      gridArea: [4, 1, 5, 2]
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, { count }) => <Count>{count}</Count>,
      textAlign: 'center',
      width: '14%',
      gridArea: [4, 2, 5, 3],
      justifyItems: 'right'
    }
  ]

  const tabs = [
    {
      label: 'По заявкам',
      content:
        ordersPosting.length > 0 ? (
          <VgTable
            onRowClick={(order) => handleClick(order.id)}
            columns={ordersColumns}
            data={ordersPosting}
            renderCollapsibleRow={(order) =>
              order.id === selectedId ? <OrderPostingRow order={ordersPosting.find((o) => o.id === selectedId)!} /> : undefined
            }
            mobileMode={isMobile}
          />
        ) : (
          <EmptyMessage>Заявки для оприходования отсутствуют</EmptyMessage>
        )
    },
    {
      label: 'По товару',
      content:
        products.length > 0 ? (
          <VgTable columns={productsColumns} data={products} mobileMode={isMobile} />
        ) : (
          <EmptyMessage>
            Выбранный товар для оприходования отсутствует. Для данного раздела настроен авторедирект. Здесь показывается только
            тот товар, который был введен в поиске при оформлении операции.
          </EmptyMessage>
        )
    }
  ]

  return <MuiTabs tabs={tabs} index={productId ? 1 : undefined} />
}
