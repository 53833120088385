import React from 'react'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { roles } from '../../types/userTypes'
import { Button, Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { PlusIcon } from '../../infrasturcture/icons'
import { ProductsContent } from './ui/ProductsContent'
import styles from './Products.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'
import { useNavigate } from 'react-router-dom'

const accessRoles: string[] = [roles.ADMINISTRATOR, roles.SUPER_MANAGER, roles.MANAGER]

export const Products: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { user } = useTypeSelector((state) => state.user)

  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileHeader>
          {accessRoles.includes(user?.role) && <PlusIcon onClick={() => navigate('/products/create')} />}
        </MobileHeader>
      )}
      <Breadcrumbs />
      <StyledHeader sx={{ mb: { xs: '32px', md: '40px' } }}>
        <Typography variant="h1">Товары</Typography>
        {!isMobile && accessRoles.includes(user?.role) && (
          <Button href="/products/create" color="primary" startIcon={<PlusIcon />}>
            Добавить товар
          </Button>
        )}
      </StyledHeader>
      <ProductsContent />
    </div>
  )
}
