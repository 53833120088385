import { Grid, SxProps, styled } from '@mui/material'

export const style: { [key: string]: SxProps } = {
  container: {
    minHeight: '100vh'
  },
  userInfo: { position: 'absolute', textAlign: 'right', right: 40 },
  username: {
    fontWeight: 500,
    fontSize: 14,
    color: '#4B4B4B',
    lineHeight: '18px'
  },
  userRole: {
    fontWeight: 400,
    fontSize: 12,
    color: '#ABABAB',
    lineHeight: '16px'
  }
}

export const Content = styled(Grid, { shouldForwardProp: (prop) => prop !== 'isMenuOpen' })<{ isMenuOpen: boolean }>(
  ({ theme, isMenuOpen }) => ({
    position: 'relative',
    flex: 1,
    marginLeft: isMenuOpen ? 244 : 88,
    width: 'calc(100% - 244px)',
    padding: '48px 40px',
    transition: 'margin 225ms ease-in-out',
    '@media screen and (max-width: 1000px)': {
      margin: '80px 0 0 0',
      padding: '20px 16px',
      width: '100%'
    }
  })
)
