export const orderStatusMessages: Record<string, string> = {
  CANCELED: 'Вы действительно хотите отменить заявку? Вместе с ним отменится и соответствующее бронирование.',
  SHIPPED_CLOSED:
    'Вы действительно хотите закрыть заявку? Закрытую заявку уже нельзя будет изменить. Все не списанные товары будут списаны автоматически со склада брони.'
}

export const bookingStatusMessages: Record<string, string> = {
  CANCELED: 'Вы действительно хотите отменить бронирование? Вместе с ним отменится и соответствующая заявка.',
  SHIPPED_CLOSED:
    'Вы действительно хотите закрыть бронирование? Закрытое бронирование уже нельзя будет изменить. Все не списанные товары будут списаны автоматически со склада брони.'
}
