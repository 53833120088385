import React, { useEffect } from 'react'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { Section } from '../../types/sectionsTypes'
import { Button, Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { PlusIcon } from '../../infrasturcture/icons'
import { VgTable, VgTableColumnType } from '../../components/VgTable/VgTable'
import { useNavigate } from 'react-router-dom'
import { LabelRow } from '../../components/Label/LabelRow'
import styles from './Sections.module.css'
import { Label } from '../../components/Label/Label'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

const sectionsColumns: VgTableColumnType<Section>[] = [
  {
    key: 'name',
    title: 'Название',
    width: '30%'
  },
  {
    key: 'subsections',
    title: 'Подразделы',
    width: '70%',
    render: (_, { subsections }) => (
      <LabelRow className={styles.tableLabelRow}>
        {subsections?.map((item, i) => (
          <Label key={`${item}-${i}`} text={item} />
        ))}
      </LabelRow>
    )
  }
]

export const Sections: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { sections } = useTypeSelector((state) => state.sections)
  const { getSectionsThunkCreator } = useActions()

  useEffect(() => {
    getSectionsThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileHeader>
          <PlusIcon onClick={() => navigate('/products/sections/create')} />
        </MobileHeader>
      )}
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Разделы</Typography>
        {!isMobile && (
          <Button href="/products/sections/create" color="primary" startIcon={<PlusIcon />}>
            Добавить раздел
          </Button>
        )}
      </StyledHeader>
      <VgTable
        className={styles.table}
        onRowClick={(item) => navigate(`/products/sections/${item.id}`)}
        data={sections}
        columns={sectionsColumns}
        mobileMode={isMobile}
      />
    </div>
  )
}
