import React from 'react'
import { Button, Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { PlusIcon } from '../../infrasturcture/icons'
import { OrdersContent } from './ui/OrdersContent'
import styles from '../../styles/Operations.module.css'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { roles } from '../../types/userTypes'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'
import { useNavigate } from 'react-router-dom'

export const Orders: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const navigate = useNavigate()
  const { user } = useTypeSelector((state) => state.user)

  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileHeader>
          {user?.role === roles.SUPER_MANAGER && <PlusIcon onClick={() => navigate('/orders/create')} />}
        </MobileHeader>
      )}
      <Breadcrumbs />
      <StyledHeader sx={{ mb: '40px' }}>
        <Typography variant="h1">Заявки</Typography>
        {!isMobile && user?.role === roles.SUPER_MANAGER && (
          <Button href="/orders/create" color="primary" startIcon={<PlusIcon />}>
            Добавить заявку
          </Button>
        )}
      </StyledHeader>
      <OrdersContent />
    </div>
  )
}
