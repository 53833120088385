import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { FormProvider, useForm } from 'react-hook-form'
import { manufacturerSchema } from '../../infrasturcture/yupSchemes/manufacturerSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, IconButton, Typography } from '@mui/material'
import { DeleteIcon, PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { Form } from './ui/Form'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import styles from './Manufacturers.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'

export const EditManufacturer: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    editManufacturerThunkCreator,
    getManufacturerThunkCreator,
    deleteManufacturerThunkCreator,
    setManufacturer,
    setManufacturersErrors
  } = useActions()
  const { isLoaded, manufacturers, manufacturer, errors } = useTypeSelector((state) => state.manufacturers)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isWarning, setIsWarning] = useState<boolean>(false)

  const methods = useForm({ resolver: yupResolver(manufacturerSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    return editManufacturerThunkCreator({ id, ...data }, () => setIsEdit(false), navigate)
  }

  const deleteManufacturer = () => {
    deleteManufacturerThunkCreator(id!, () => navigate('/products/manufacturers'), navigate)
    setIsWarning(false)
  }

  const handleEdit = () => setIsEdit(true)
  const handleWarning = () => setIsWarning(true)

  const handleFetch = async (id: string) => {
    const findManufacturer = manufacturers?.find((m) => m.id === id)
    findManufacturer ? setManufacturer(findManufacturer) : getManufacturerThunkCreator(id, navigate)
  }

  useEffect(() => {
    handleFetch(id!)
  }, [])

  useEffect(() => {
    manufacturer && methods.reset(manufacturer)
  }, [manufacturer])

  return (
    <div className={styles.container}>
      {!isLoaded && manufacturer && (
        <>
          {isMobile && (
            <MobileHeader>
              {isEdit ? (
                <>
                  <SaveIcon onClick={methods.handleSubmit(onSubmit)} />
                  <DeleteIcon onClick={handleWarning} />
                </>
              ) : (
                <PenIcon onClick={handleEdit} />
              )}
            </MobileHeader>
          )}
          <Breadcrumbs customText={manufacturer?.name} />
          <StyledHeader>
            <Typography variant="h1">{manufacturer?.name}</Typography>
            {!isMobile && (
              <>
                {isEdit ? (
                  <>
                    <Button
                      onClick={methods.handleSubmit(onSubmit)}
                      disabled={methods.formState.isSubmitting}
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      Сохранить
                    </Button>
                    <Button onClick={handleWarning} color="error" startIcon={<DeleteIcon />}>
                      Удалить
                    </Button>
                  </>
                ) : (
                  <IconButton color="inherit" onClick={handleEdit}>
                    <PenIcon />
                  </IconButton>
                )}
              </>
            )}
          </StyledHeader>
          <FormProvider {...methods}>
            <Form type="edit" onSubmit={onSubmit} disabled={!isEdit} />
          </FormProvider>
        </>
      )}
      <SnacbarModal
        onAccept={deleteManufacturer}
        onCancel={() => setIsWarning(false)}
        open={isWarning}
        message={`Вы действительно хотите удалить производителя '${manufacturer?.name}'`}
      />
      <SnacbarModal
        isError
        isActions={false}
        onCancel={() => setManufacturersErrors({})}
        open={!!errors?.id}
        message={errors?.id}
      />
    </div>
  )
}
