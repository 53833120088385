import { formatISO } from 'date-fns'
import { instance } from '../infrasturcture/http'
import { getQueryString } from '../infrasturcture/utils/getQueryString'
import { Operation, OperationFilters } from '../types/operationsTypes'

export default class OperationsApi {
  static async getOperations(
    limit = 50,
    page = 1,
    { type, managers, products, warehouses, startDate, endDate, sku, quickFilter }: OperationFilters
  ) {
    const response = await instance.get('operations', {
      params: {
        limit,
        page,
        type,
        managers: getQueryString(managers, 'id') || null,
        products: getQueryString(products, 'id') || null,
        warehouses: getQueryString(warehouses, 'id') || null,
        date: startDate ? `${formatISO(startDate)},${endDate ? formatISO(endDate) : formatISO(startDate)}` : null,
        sku: sku || null,
        quickFilter: quickFilter || null
      }
    })
    return response.data.data
  }

  static async getOperation(id: string) {
    const response = await instance.get(`operations/${id}`)
    return response.data.data
  }

  static async createOperation(operation: Operation) {
    const response = await instance.post('operations', operation)
    return response.data.data
  }

  static async editOperation(operation: Operation) {
    const response = await instance.post(`operations/${operation.id}`, { purpose: operation.purpose })
    return response.data.data
  }

  static async getManagers() {
    const response = await instance.get('managers')
    return response.data.data
  }

  static async getProducts(id: string) {
    const response = await instance.get(`operations/products/${id}`)
    return response.data.data
  }
}
