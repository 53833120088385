import React, { KeyboardEvent, useState } from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Input } from '../../../components/Input/Input'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { PlusIcon } from '../../../infrasturcture/icons'
import { LabelSelected } from '../../../components/Label/LabelSelected'
import { useActions } from '../../../hooks/useActions'
import { StyledInput, StyledInputContainer, StyledSelectedContainer } from '../../../infrasturcture/theme/styled'
import styles from '../../../styles/Form.module.css'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

interface FormProps {
  onSubmit: SubmitHandler<any>
  type: 'create' | 'edit'
  disabled?: boolean
}

export const Form: React.FC<FormProps> = ({ onSubmit, disabled = false, type }) => {
  const { isMobile } = useMediaQueryContext()
  const { setSectionsErrors } = useActions()
  const { errors: stateErrors } = useTypeSelector((state) => state.sections)
  const isDisabled = type === 'edit' && disabled

  const [subsection, setSubsection] = useState<string>('')

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext()

  const subsections: string[] = watch('subsections') || []

  const addSubsection = () => {
    if (subsection) {
      setValue(
        'subsections',
        [...subsections, subsection].filter((item, i, arr) => arr.indexOf(item) === i)
      )
      setSubsection('')
    }
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      addSubsection()
    }
  }

  const deleteSubsection = (value: string) => {
    setValue(
      'subsections',
      subsections.filter((item) => item !== value)
    )
  }

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItem}>
          <Typography variant="h2" color="text.primary">
            Общая информация
          </Typography>
          <Grid className={styles.fields} container spacing={3}>
            <Grid item xs={12} md={6}>
              <Input
                onChange={() => setSectionsErrors({})}
                control={control}
                label={(stateErrors?.name || errors?.name?.message) ?? 'Название раздела'}
                placeholder="Название раздела"
                name="name"
                error={!!stateErrors?.name || !!errors?.name?.message}
                disabled={isDisabled}
                required
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.formItem}>
          <Typography variant="h2" color="text.primary">
            Подразделы
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
              {!isDisabled && (
                <Box sx={{ display: 'flex', alignItems: 'center', mt: '20px' }}>
                  <StyledInputContainer>
                    <StyledInput
                      onChange={(e) => setSubsection(e.target.value)}
                      onKeyDown={handleKeyDown}
                      value={subsection}
                      name="subsection"
                      placeholder="Введите название подраздела"
                      autoComplete="off"
                    />
                  </StyledInputContainer>
                  <Button
                    onClick={addSubsection}
                    color="primary"
                    startIcon={<PlusIcon />}
                    sx={{
                      height: '32px',
                      p: '8px 10px',
                      fontWeight: 400,
                      flexShrink: 0,
                      '& .MuiButton-startIcon': { width: '8px', mr: '8px' }
                    }}
                  >
                    {isMobile ? 'Добавить' : 'Добавить подраздел'}
                  </Button>
                </Box>
              )}
              <StyledSelectedContainer>
                {subsections?.map((text, i) => (
                  <LabelSelected onClick={() => deleteSubsection(text)} key={i} text={text} disabled={disabled} />
                ))}
              </StyledSelectedContainer>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  )
}
